import 'dayjs/locale/id'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const getDateTimeUTC = (date: any): string => {
  return dayjs(date).utc().format()
}

const isValidDate = (dateObject) => {
  return new Date(dateObject).toString() !== 'Invalid Date'
}

const isValidMapsURL = (value) => {
  if (!value) {
    return true
  }

  let urlPrefixes = [
    'https://www.google.com/maps/',
    'https://google.com/maps/',
    'https://goo.gl/maps/',
    'https://maps.google.com/maps/',
    'www.google.com/maps/',
    'google.com/maps/',
    'goo.gl/maps/',
    'maps.google.com/maps/',
    'https://maps.app.goo.gl/',
  ]

  return urlPrefixes.some((url) => value.startsWith(url))
}

const convertTZ = (date, tzString) => {
  return new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString(
      'en-US',
      { timeZone: tzString },
    ),
  )
}

export { getDateTimeUTC, isValidDate, isValidMapsURL, convertTZ }
