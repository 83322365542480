import { trackEvent, getStateFromSource } from 'helpers/analytics'
import isEmpty from 'helpers/is-empty'

export default function PromoterTracker(
  eventName: string,
  data?: any,
  additionalProperties?: {},
) {
  const getSubName = (subName: string) => {
    return subName === 'content'
      ? 'digital_content'
      : subName === 'event'
      ? 'e_ticket'
      : 'subscription'
  }
  const { pathname, query, user, productType } = data
  const state_name = getStateFromSource(pathname) || ''
  const state_sub_name = getSubName(productType)
  const source = isEmpty(query.source)
    ? 'unknown'
    : getStateFromSource(String(query.source))
  const source_action = isEmpty(query.source_action)
    ? 'unknown'
    : String(query.source_action)

  const baseProperties: any = {
    state_name: state_name,
    state_sub_name: state_sub_name,
    user_type: user?.role?.toLowerCase(),
  }
  const sourceProperties: any = {
    source,
    source_action,
  }

  const eventProperties: any = {
    visit_about_promoter: {
      ...sourceProperties,
      ...baseProperties,
      ...additionalProperties,
    },
    select_digital_content_tab: {
      ...baseProperties,
      ...additionalProperties,
    },
    select_eticket_tab: {
      ...baseProperties,
      ...additionalProperties,
    },
    select_subscription_tab: {
      ...baseProperties,
      ...additionalProperties,
    },
    visit_promoter_performance: {
      ...sourceProperties,
      ...baseProperties,
      ...additionalProperties,
    },
    visit_promote_content: {
      ...sourceProperties,
      ...baseProperties,
      ...additionalProperties,
    },
    click_promote_content: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_promote_event: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_promote_subscription: {
      ...baseProperties,
      ...additionalProperties,
    },
    submit_promote_content: {
      ...baseProperties,
      ...additionalProperties,
    },
    submit_promote_event: {
      ...baseProperties,
      ...additionalProperties,
    },
    submit_promote_subscription: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_change_start_date: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_change_end_date: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_reset_date: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_choose_page: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_about_promoter: {
      ...baseProperties,
    },
    click_start_promote_content: {
      ...baseProperties,
    },
    click_promoter_performance: {
      ...baseProperties,
    },
    click_search_box: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_search_keyword: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_filter: {
      ...baseProperties,
    },
    click_sort: {
      ...baseProperties,
    },
    click_tnc: {
      ...baseProperties,
    },
    select_content: {
      ...baseProperties,
      ...additionalProperties,
    },
    select_event: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_subscribe: {
      ...baseProperties,
      ...additionalProperties,
    },
    apply_filter_option: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_clear_filter: {
      ...baseProperties,
      ...additionalProperties,
    },
    apply_sort_option: {
      ...baseProperties,
      ...additionalProperties,
    },
    cancel_promote_content: {
      ...baseProperties,
      ...additionalProperties,
    },
    cancel_promote_event: {
      ...baseProperties,
      ...additionalProperties,
    },
    cancel_promote_subscription: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_promoter_handbook: {
      ...baseProperties,
      ...additionalProperties,
    },
  }

  return trackEvent(eventName, eventProperties[eventName])
}
