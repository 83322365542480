import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { createVerificationUserSlice } from './VerificationUser'
import { createVerificationCoinSlice } from './VerificationCoin'
import { IVerificationUser } from './VerificationUser/interface'
import { IVerificationCoin } from './VerificationCoin/interface'

export const useBoundVerificationUserStore = create<
  IVerificationUser & IVerificationCoin
>()(
  persist(
    (...a) => ({
      ...createVerificationUserSlice(...a),
      ...createVerificationCoinSlice(...a),
    }),
    {
      name: 'user-kyc-store',
      partialize: (state) => ({
        activeStep: state.activeStep,
        personalData: state.personalData,
      }),
    },
  ),
)
