export const CURRENT_PATH = 'currentNewPath'
export const CURRENT_QUERY = 'currentNewQuery'
export const PREV_PATHS = 'prevPaths'
export const NO_BACK_URLS = [
  '/creator/session/live/',
  '/session/live/',
]
export const SKIP_SESSION_URLS = [
  '/auth/exchange-token/',
  '/auth/verify/',
]
export const SSO = ['google', 'appleid']
