import apiClient from 'helpers/api-client'
import { IDynamicForm } from '~/@types/commons'

export const getCommunityInfo = async () => {
  const res = await apiClient({
    url: '/social/hub/v1/community/info',
    method: 'GET',
  })
  return res.data
}

export const updateCommunityType = async (id: string, payload) => {
  return await apiClient({
    url: `/social/hub/v1/community/${id}/type`,
    method: 'PUT',
    data: payload,
  })
}

export const getCommunityPendingMembers = async (
  id: string,
  params,
) => {
  return await apiClient({
    url: `/social/hub/v1/community/${id}/member/pending`,
    method: 'GET',
    params,
  })
}

export const putApproveRejectPendingMember = async (
  communityId: string,
  userId: string,
  payload: {
    is_approve: boolean
    reason: string
  },
) => {
  return await apiClient({
    url: `/social/hub/v1/community/${communityId}/member/${userId}`,
    method: 'PUT',
    data: payload,
  })
}

export const putScreeningQuestion = async (
  communityId: string,
  payload: IDynamicForm,
) => {
  return await apiClient({
    url: `/social/hub/v1/community/${communityId}/form`,
    method: 'PUT',
    data: payload,
  })
}

export const getScreeningQuestion = async (communityId: string) => {
  return await apiClient({
    url: `/social/hub/v1/community/${communityId}/form`,
    method: 'GET',
  })
}

export const getScreeningQuestionAnswer = async (
  communityId: string,
  userId: string,
) => {
  return await apiClient({
    url: `/social/hub/v1/community/${communityId}/answer/${userId}`,
    method: 'GET',
  })
}
