import {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useState,
} from 'react'
// utils
import apiClient from 'helpers/api-client'
import { isValidToken, setSession } from 'utils/jwt'
// @types
import {
  ActionMap,
  AuthState,
  AuthUser,
  AuthContextType,
} from '~/@types/auth'
import { DataResponse } from '~/@types/commons'
import {
  CreatorClass,
  UserAccess,
  UserProfile,
  UserTypes,
} from '~/@types/profile'
// routes
import { API_USER } from 'routes/apis'
// configs
import isEmpty from 'helpers/is-empty'
import { PATH_AUTH, PATH_DASHBOARD } from 'routes/paths'
import router, { useRouter } from 'next/router'
import { getPreviousURL } from 'helpers/routing'
import { addBreadcrumb, sendLog } from 'helpers/log'
import {
  DeliveryMethod,
  VerificationAction,
} from 'domains/Users/Auth/store/Auth/interface'
import {
  destroySessionStorage,
  setSessionStorage,
} from 'helpers/session-storage'
import trackEvent from '~/trackers'
import { useAuthStore } from 'domains/Users/Auth/store'
import { getIdToken } from 'helpers/auth'
import { getStateFromSource, setUserLogin } from 'helpers/analytics'
import { getDateTimeUTC } from 'helpers/date-time'
import { setUserDevice } from 'helpers/user-device'
import {
  destroyLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from 'helpers/local-storage'
import { useCommunityInfo } from 'domains/Community/hooks/useCommunity'
import authConfig from 'configs/auth'
import { useBoundVerificationUserStore } from 'domains/Users/Verification/stores'
import { resetMyBalanceStore } from 'domains/Balance/store/MyBalance'
import { useUserKYCTier3 } from 'domains/Users/Verification/hooks/useVerificationCoin'
import { IStatusTierThree } from 'domains/Users/Verification/stores/VerificationCoin/interface'
import { renderErrorMessage } from 'helpers/auth/error-auth'
import redirect from 'helpers/redirect'
import { MAIN_APP_URL } from 'constants/index'
import { setCookie } from 'helpers/cookie'

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  LoginPhone = 'LOGIN_PHONE',
  LoginSocial = 'LOGIN_SOCIAL',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
  Authenticated = 'AUTHENTICATED',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean
    user: AuthUser
  }
  [Types.Login]: {
    user: AuthUser
  }
  [Types.LoginPhone]: {
    user: AuthUser
    action: string
    verificationMethod: DeliveryMethod
  }
  [Types.LoginSocial]: {
    user: AuthUser
  }
  [Types.Logout]: undefined
  [Types.Register]: {
    user: AuthUser
  }
  [Types.Authenticated]: {
    user: AuthUser
  }
}

export type JWTActions =
  ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>]

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  isOtp: false,
  user: null,
}

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        isOtp: false,
        user: action.payload.user,
      }
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        isInitialized: true,
        isOtp: false,
        user: action.payload.user,
      }
    case 'LOGIN_PHONE':
      return {
        ...state,
        ...action.payload,
        isAuthenticated: false,
        isOtp: true,
      }
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        isInitialized: false,
        isOtp: false,
        user: null,
      }
    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        isOtp: false,
        user: action.payload.user,
      }
    case 'AUTHENTICATED':
      return {
        ...state,
        ...action.payload,
        isOtp: false,
        isAuthenticated: true,
      }
    default:
      return state
  }
}

const AuthContext = createContext<AuthContextType | null>(null)

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode
}

function AuthProvider({ children }: AuthProviderProps) {
  const { pathname, query } = useRouter()
  const trackerData = { pathname, query }
  const [state, dispatch] = useReducer(JWTReducer, initialState)
  const [roles, setRoles] = useState<string[]>()
  const sessionID = getLocalStorage('session_id')
    ? JSON.parse(getLocalStorage('session_id'))
    : ''
  const { verificationMethod, countryPhone } = useAuthStore(
    (state) => ({
      verificationMethod: state.verificationMethod,
      countryPhone: state.countryPhone,
    }),
  )
  const { resetData: resetKycData } = useBoundVerificationUserStore()

  const { refetch: fetchCommunityInfo } = useCommunityInfo()
  const { refetch: fetchKYCTierThree } = useUserKYCTier3()

  const getUserProfile = async () => {
    try {
      const headers = {
        Authorization: getIdToken(),
      }
      const response = await apiClient.get<DataResponse<UserProfile>>(
        API_USER.profile.root,
        {
          headers,
        },
      )
      setLocalStorage('userId', {
        id: response.data?.data.user_id,
      })
      return Promise.resolve(response.data)
    } catch (error) {
      if (error.code === 'POST_REGISTRATION_INCOMPLETE') {
        redirect(
          `${MAIN_APP_URL}/user/post-registration?source=${getStateFromSource(
            pathname,
          )}`,
        )
      }
      return Promise.reject(error.message)
    }
  }

  const getUserKYCTier3 = async () => {
    try {
      const response = await fetchKYCTierThree<IStatusTierThree>()
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error.message)
    }
  }

  const userKYCTier3 = async () => {
    const kycTierThree = await getUserKYCTier3()
    dispatch({
      type: Types.Initial,
      payload: {
        isAuthenticated: true,
        user: {
          ...state.user,
          kyc_tier_three: kycTierThree,
        },
      },
    })
  }

  const getAccessList = async () => {
    try {
      const response = await apiClient.get<DataResponse<UserAccess>>(
        API_USER.role.root,
      )
      return Promise.resolve(response.data.data)
    } catch (error) {
      return Promise.reject(error.message)
    }
  }

  const mappingRoleName = (role: CreatorClass) => {
    switch (role) {
      case 'CREATOR':
        return 'Kreator'
      case 'BUSINESS':
        return 'Badan Usaha'
      case 'PERSONAL':
      default:
        return 'Personal'
    }
  }

  const doUserAuthenticated = async () => {
    try {
      const { data: user } = await getUserProfile()
      const kycTierThree = await getUserKYCTier3()
      await fetchCommunityInfo()
      const displayName = user.creator.name || user.username || 'User'
      const { access } = await getAccessList()
      setRoles(access)
      trackEvent.auth('sign_in_completed', {
        ...trackerData,
        verificationMethod,
      })

      setUserLogin(user.user_id, {
        user_id: user.user_id,
        user_type: user.creator.creator_class,
        $email: user.email,
        $avatar: user.avatar,
        $name: user.username,
        phone_number: user.phone,
        gender: user.gender,
        birth_date:
          String(user.dob) !== ''
            ? getDateTimeUTC(new Date(user.dob).toISOString())
            : '',
        creator_name: user?.creator?.name || '',
        creator_status: user?.creator?.status || '',
        creator_category_id: user?.creator?.category || '',
        creator_category: user?.creator?.category_name || '',
      })
      setUserDevice()
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: true,
          user: {
            displayName,
            photoURL: user.avatar,
            role: UserTypes.Creator,
            kyc_tier_three: kycTierThree,
            roleName: mappingRoleName(user?.creator?.creator_class),
            ...user,
          },
        },
      })
    } catch (error) {
      if (error == 'user is inactivated') {
        logout()
      }
    }
  }

  const doUserUnauthorized = async () => {
    dispatch({
      type: Types.Initial,
      payload: {
        isAuthenticated: false,
        user: null,
      },
    })
  }

  const handleCheckSSO = async () => {
    try {
      const response = await apiClient.get(
        API_USER.auth.getSSOToken + `/${sessionID}`,
      )
      if (response?.data?.code === 'SUCCESS') {
        const token = response?.data?.data?.id_token
        const refreshToken = response?.data?.data?.refresh_token
        setSession(token)
        destroyLocalStorage('session_id')
        setLocalStorage(authConfig.refreshTokenName, refreshToken)
        await doUserAuthenticated()
      }
    } catch (error) {
      addBreadcrumb(
        'auth',
        'auth listen Get SSO Token',
        'error',
        error,
      )
      sendLog(error)
    }
  }

  const loginPhone = async (
    phone: string,
    method: DeliveryMethod,
    action = 'SIGN_IN',
  ) => {
    const phoneNumber = `${countryPhone.dial_code}${+phone}`
    setSessionStorage('authMethod', 'phone_number')
    try {
      const response = await apiClient.post(
        API_USER.auth.requestOtpByPhone,
        {
          phone_number: phoneNumber,
          action,
          delivery_method: method,
        },
      )
      if (response?.data?.code === 'SUCCESS') {
        dispatch({
          type: Types.LoginPhone,
          payload: {
            action,
            verificationMethod: method,
            user: {
              phoneNumber,
            },
          },
        })
      }
    } catch (error) {
      sendLog(error)
      return Promise.reject(error)
    }
  }

  const login = async (email: string, password: string) => {
    setSessionStorage('authMethod', 'email')
    destroySessionStorage('userPhoneNumber')

    setSessionStorage('userEmail', email)

    try {
      const response = await apiClient.post(
        API_USER.auth.signInByEmail,
        {
          email: email,
          password: password,
        },
      )
      if (response?.data?.code === 'SUCCESS') {
        const { id_token: token, refresh_token: refreshToken } =
          response.data.data
        setSession(token)
        setLocalStorage(authConfig.refreshTokenName, refreshToken)
        await doUserAuthenticated()
      }
    } catch (error) {
      sendLog(error)
      return Promise.reject(error)
    }
  }

  const loginSocial = async (provider: string) => {
    try {
      setSessionStorage('authMethod', 'google')
      const response = await apiClient.get(
        API_USER.auth.signInBySSO + `/${provider}`,
      )
      if (response?.data?.code === 'SUCCESS') {
        setLocalStorage(
          'session_id',
          response?.data?.data?.session_id,
        )
        redirect(response?.data?.data?.url)
      }
    } catch (error) {
      addBreadcrumb('auth', 'auth listen signIn_failure', 'error', {
        error,
      })
      sendLog(error)
    }
  }

  const verify = async (
    otp: string,
    delivery_method: DeliveryMethod,
    username: string,
    action = 'SIGN_IN',
  ) => {
    try {
      const response = await apiClient.post(API_USER.auth.verifyOtp, {
        phone_number: username,
        action,
        delivery_method,
        otp: Number(otp),
      })
      if (response?.data?.code === 'SUCCESS') {
        try {
          const loginResponse = await apiClient.post(
            API_USER.auth.signInByPhone,
            {
              phone_number: username,
              is_new_user: false,
            },
          )
          if (loginResponse?.data?.code === 'SUCCESS') {
            const { id_token: token, refresh_token: refreshToken } =
              loginResponse.data.data
            setSession(token)
            await doUserAuthenticated()
            setLocalStorage(authConfig.refreshTokenName, refreshToken)
          }
        } catch (error) {
          sendLog(error)
          return Promise.reject(error)
        }
      }
    } catch (error) {
      sendLog(error)
      return Promise.reject(error)
    }
  }

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
  ) => {
    const response = await apiClient.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    })
    const { accessToken, user } = response.data
    setSession(accessToken)

    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    })
  }

  const resetDataAfterLogout = () => {
    destroySessionStorage('hideQRCodeAlert')
    setSession(null)
    dispatch({ type: Types.Logout })
    resetKycData()
    resetMyBalanceStore()
    useBoundVerificationUserStore.persist.clearStorage()
  }

  const logout = async () => {
    await apiClient
      .post(API_USER.auth.signOut, {
        refresh_token: getLocalStorage(authConfig.refreshTokenName),
      })
      .then(() => {
        setSession(null)
        resetDataAfterLogout()
      })
  }

  const claimToken = async (tokenId: string) => {
    setSession(null)
    try {
      const response = await apiClient.post(
        API_USER.auth.tokenClaim,
        {
          token_id: tokenId,
        },
      )
      const { auth_token_id: authTokenId = '' } = response.data.data
      if (!authTokenId) return Promise.reject('Invalid token')
      setSession(authTokenId)
      await doUserAuthenticated()
      return Promise.resolve(authTokenId)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getRedirectUrl = () => {
    if (!state.isAuthenticated) return PATH_AUTH.login
    const targetDashboardUrl =
      state.user?.role === UserTypes.Creator
        ? PATH_DASHBOARD.creator_center.dashboard
        : PATH_DASHBOARD.general.promoter_program.about
    const previousUrl = getPreviousURL()
    return previousUrl === '/' || previousUrl === PATH_DASHBOARD.root
      ? targetDashboardUrl
      : previousUrl
  }

  const sendOTP = async (
    phone: string,
    method: DeliveryMethod,
    action: VerificationAction = 'SIGN_IN',
  ) => {
    const phoneNumber = `${countryPhone.dial_code}${+phone}`
    try {
      const response = await apiClient.post(
        API_USER.auth.requestHubOtp,
        {
          username: phoneNumber,
          action,
          delivery_method: method,
        },
      )
      if (response?.data?.code === 'SUCCESS') {
        return Promise.resolve(response)
      }
    } catch (error) {
      sendLog(error)
      const errorMessage = renderErrorMessage(
        error?.errors?.[0]?.err || error?.code,
        {
          seconds: error.data?.retry_in_secs,
          defaultMessage: error.message,
        },
      )
      return Promise.reject(errorMessage)
    }
  }

  const forceLogout = async () => {
    setSession(null)
    resetDataAfterLogout()
    router.push(PATH_AUTH.login)
  }

  const initialize = async () => {
    const idToken = getIdToken()
    if (!isEmpty(idToken)) {
      // try {
      //   setSession(idToken)
      //   // const { code } = await getUserProfile()
      //   // if (code === 'SUCCESS') {
      //   await doUserAuthenticated()
      //   // }
      // } catch (error) {
      if (idToken && isValidToken(idToken)) {
        setSession(idToken)
        doUserAuthenticated()
      } else {
        setSession(null)
        router.push(PATH_AUTH.login)
      }
      // }
    } else {
      forceLogout()
    }
  }

  useEffect(() => {
    initialize()
  }, [])

  useEffect(() => {
    if (sessionID) {
      handleCheckSSO()
    }
  }, [sessionID])

  return (
    <AuthContext.Provider
      value={{
        ...state,
        loginPhone,
        login,
        loginSocial,
        logout,
        register,
        verify,
        claimToken,
        roles: roles || [],
        targetRedirectUrl: getRedirectUrl(),
        getUserProfile,
        getUserKYCTier3,
        userKYCTier3,
        sendOTP,
        doUserAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
