import { createContext, useState, useMemo } from 'react'
// component
import ProductPopup from 'domains/Content/containers/ProductPopup'

// ----------------------------------------------------------------------

type ContextType = {
  isOpenProductModal: boolean
  openProductModal: Function
}

export const ProductPopupContext = createContext<ContextType | null>(
  null,
)

export function ProductPopupProvider({ children }) {
  const [isOpenProductModal, openProductModal] = useState(false)

  let contextValue = useMemo(() => {
    return {
      isOpenProductModal,
      openProductModal: (isOpen) => openProductModal(isOpen),
    }
  }, [isOpenProductModal])

  return (
    <ProductPopupContext.Provider value={contextValue}>
      {children}
      <ProductPopup
        isOpenProductModal={isOpenProductModal}
        openProductModal={openProductModal}
      />
    </ProductPopupContext.Provider>
  )
}
