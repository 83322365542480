export const APP_DOMAIN = process.env.NEXT_PUBLIC_APP_DOMAIN
export const ORGANIZER_APP_DOMAIN =
  process.env.NEXT_PUBLIC_ORGANIZER_APP_DOMAIN
export const MAIN_APP_URL = process.env.NEXT_PUBLIC_WEB_APP_MAIN_URL
export const ORGANIZER_APP_URL =
  process.env.NEXT_PUBLIC_ORGANIZER_APP_URL
export const CDN_URL = process.env.NEXT_PUBLIC_CDN_URL
export const STORE_NAME = process.env.NEXT_PUBLIC_STORE_NAME
export const SOFT_LAUNCH = process.env.NEXT_PUBLIC_SOFT_LAUNCH
export const DEEPLINK_URL = process.env.NEXT_PUBLIC_DEEPLINK_URL
export const CREATOR_ALTERNATE_LAYOUT =
  process.env.NEXT_PUBLIC_CREATOR_ALTERNATE_LAYOUT === 'true'
    ? true
    : false
export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID
export const GOOGLE_TAG_MANAGER =
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENABLED === 'true'
export const WITHDRAWAL_MIN = parseInt(
  process.env.NEXT_PUBLIC_WITHDRAWAL_MIN,
)
