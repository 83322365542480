import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
// config
import { defaultLang } from '../config'
//
import idLocales from './id.json'
import enLocales from './en.json'
import { getLocalStorage } from 'helpers/local-storage'
// import frLocales from './fr'
// import vnLocales from './vn'
// import cnLocales from './cn'
// import arLocales from './ar'

// ----------------------------------------------------------------------

let lng = defaultLang.value

if (typeof window !== 'undefined') {
  lng = getLocalStorage('i18nextLng') || defaultLang.value
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: enLocales },
      id: { translations: idLocales },
      // fr: { translations: frLocales },
      // vn: { translations: vnLocales },
      // cn: { translations: cnLocales },
      // ar: { translations: arLocales },
    },
    lng,
    fallbackLng: defaultLang.value,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      prefix: '{', // Custom prefix for interpolation
      suffix: '}', // Custom suffix for interpolation
      escapeValue: false, // React already does escaping
    },
  })

export default i18n
