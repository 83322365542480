import isEmpty from 'helpers/is-empty'
import {
  destroyLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from 'helpers/local-storage'
import {
  destroySessionStorage,
  setSessionStorage,
} from 'helpers/session-storage'
import { StateCreator } from 'zustand'
import { DeliveryMethod, IAuth } from './interface'

export const createAuthStore: StateCreator<IAuth> = (set) => ({
  //states
  isOpenVerificationDrawer: false,
  verificationMethod: '',
  isError: false,
  isLoading: false,
  errorMessage: '',
  inputEmail: '',
  inputPhoneNumber: '',
  authTab: 'phone_number',
  countryPhone: {
    country_code: 'ID',
    dial_code: '+62',
    allow_sms: true,
  },
  //actions
  setIsOpenVerificationDrawer: (status: boolean) => {
    set({
      isOpenVerificationDrawer: status,
    })
  },
  setVerificationMethod: (method: DeliveryMethod) => {
    setLocalStorage('verificationMethod', {
      method,
    })
    set({
      verificationMethod: method,
    })
  },
  setInputPhoneNumber: (phoneNumber: string) => {
    setSessionStorage('inputPhoneNumber', phoneNumber)
    set({
      inputPhoneNumber: phoneNumber,
    })
  },
  setCountryPhone: (data) => {
    set({
      countryPhone: data,
    })
  },
  checkVerificationMethod: (verificationMethod: string) => {
    // Prevent verification method disapear when user refresh page
    const tempVerificationMethod = getLocalStorage(
      'verificationMethod',
    )?.method

    // If zustand is empty after refresh page, re-assign value from local storage to zustand for verification method
    if (
      isEmpty(verificationMethod) &&
      !isEmpty(tempVerificationMethod)
    ) {
      set({
        verificationMethod: tempVerificationMethod,
      })
    }
  },
  clearAuthStore: () => {
    destroySessionStorage('inputPhoneNumber')
    destroyLocalStorage('verificationMethod')
    set({
      isOpenVerificationDrawer: false,
      isError: false,
      isLoading: false,
      errorMessage: '',
      verificationMethod: 'WA',
      inputPhoneNumber: '',
      countryPhone: {
        country_code: 'ID',
        dial_code: '+62',
        allow_sms: true,
      },
    })
  },
  resetCountryPhone: () => {
    set({
      countryPhone: {
        country_code: 'ID',
        dial_code: '+62',
        allow_sms: true,
      },
    })
  },
  setAuthTab: (activeTab: 'email' | 'phone_number') => {
    set({
      authTab: activeTab,
    })
  },
})
