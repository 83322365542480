import { StateCreator } from 'zustand'
import { ICommunitySetting } from './interface'

export const defaultStateCommunityInfo = {
  community_id: '',
  name: '',
  category: {
    id: 0,
    name: '',
  },
  description: '',
  is_private: false,
  thumbnail_image: '',
  isError: false,
  errorCode: '',
}

const defaultStateDynamicScreeningQuestion = {
  form_id: '',
  form_name: '',
  form_description: '',
  fields: [],
}

export const createCommunitySettingSlice: StateCreator<
  ICommunitySetting
> = (set, get) => ({
  // states
  communityInfo: defaultStateCommunityInfo,
  totalPendingMembers: 0,
  openRejectModal: false,
  screeningQuestions: defaultStateDynamicScreeningQuestion,
  screeningQuestionAnswer: {
    form_answer: {},
    form_question: defaultStateDynamicScreeningQuestion,
  },

  //actions
  setCommunityInfo(data) {
    set(() => {
      return {
        communityInfo: data,
      }
    })
  },
  setCommunityType(data) {
    set(() => {
      return {
        communityInfo: {
          ...get().communityInfo,
          is_private: data,
        },
      }
    })
  },
  setTotalPendingMembers(data) {
    set(() => {
      return {
        totalPendingMembers: data,
      }
    })
  },
  setOpenRejectModal(data) {
    set(() => {
      return {
        openRejectModal: data,
      }
    })
  },
  setScreeningQuestionData(data) {
    set(() => {
      return {
        screeningQuestions: data,
      }
    })
  },
  setScreeningQuestionAnswer(data) {
    set(() => {
      return { screeningQuestionAnswer: data }
    })
  },
  resetScreeningQuestionAnswer() {
    set(() => {
      return {
        screeningQuestionAnswer: {
          form_answer: {},
          form_question: defaultStateDynamicScreeningQuestion,
        },
      }
    })
  },
})
