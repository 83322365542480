import jwtDecode from 'jwt-decode'
import { PATH_AUTH } from '../routes/paths'
import apiClient from 'helpers/api-client'
import { getBrowser } from 'helpers/user-agent'
import { destroyIdToken, setIdToken } from 'helpers/auth'
import { sendLog } from 'helpers/log'
import { clearLocalStorageExcept } from 'helpers/local-storage'

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken)

  const currentTime = Date.now() / 1000

  return decoded.exp > currentTime
}

const isTokenExpired = (exp: number) => {
  const currentTime = Date.now() / 1000
  return exp <= currentTime
}

const handleTokenExpired = (exp: number) => {
  const tokenExpired = isTokenExpired(exp)

  if (tokenExpired) {
    sendLog('Token expired')
    destroyIdToken()
    window.location.href = PATH_AUTH.login
  }
}

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    setIdToken(accessToken)
    apiClient.defaults.headers.common.Authorization = `${accessToken}`

    // This function below will handle when token is expired
    const { exp } = jwtDecode<{ exp: number }>(accessToken) // ~3 days by minimals server
    handleTokenExpired(exp)
  } else {
    clearLocalStorageExcept(['i18nextLng'])
    destroyIdToken()
    delete apiClient.defaults.headers.common.Authorization
  }

  apiClient.defaults.headers.common['Channel-Device'] =
    getBrowser(false)
}

export { isValidToken, setSession }
