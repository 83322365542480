import isEmpty from 'helpers/is-empty'
import { getSessionStorage } from 'helpers/session-storage'
import { NO_BACK_URLS, PREV_PATHS } from 'constants/routing'
import { PathQueryType } from '~/contexts/RoutingContext'

export function getPreviousURL(): string {
  let basePaths = getSessionStorage(PREV_PATHS) as PathQueryType[]
  if (isEmpty(basePaths)) {
    return '/'
  }
  if (
    NO_BACK_URLS.some((blockedRoute) =>
      basePaths
        .map((val) => val.path)
        .some((path) => path.includes(blockedRoute)),
    )
  ) {
    return '/'
  }
  const lastPath = basePaths.pop()
  return lastPath?.path || '/'
}
