import { StateCreator } from 'zustand'
import {
  IBankAccount,
  IPersonalData,
  IVerificationUser,
} from './interface'

const DEFAULT_STATE_BANK_ACCOUNT_STATE: IBankAccount = {
  bank_account_name: '',
  bank_account_no: '',
  bank_id: 0,
  is_different_name: false,
}

export const DEFAULT_STATE_PERSONAL_DATA: IPersonalData = {
  full_name: '',
  email: '',
  auth_phone: '',
  address: '',
  address_province: '',
  address_district: '',
  address_sub_district: '',
  address_village: '',
  address_postal_code: '',
  is_same_address: false,
  residence_address: '',
  residence_address_province: '',
  residence_address_district: '',
  residence_address_sub_district: '',
  residence_address_village: '',
  residence_address_postal_code: '',
  id_card: '',
  id_card_type: 1,
  id_card_photo: '',
  selfie_photo: '',
  id_card_photo_file_name: '',
  selfie_photo_file_name: '',
  ...DEFAULT_STATE_BANK_ACCOUNT_STATE,
  bank_account_status: 1,
  npwp_number: '',
  npwp_type: 'PRIBADI',
  npwp_photo: '',
  npwp_photo_file_name: '',
  have_npwp: false,
  reject_reason: '',
}

export const createVerificationUserSlice: StateCreator<
  IVerificationUser
> = (set) => ({
  personalData: DEFAULT_STATE_PERSONAL_DATA,
  activeStep: null,
  province: [],
  errorRejection: {},
  tempBankAccount: DEFAULT_STATE_BANK_ACCOUNT_STATE,

  setPersonalData(data) {
    set(() => {
      return {
        personalData: data,
      }
    })
  },
  setActiveStep(data) {
    set(() => {
      return {
        activeStep: data,
      }
    })
  },
  setProvinceData(data) {
    set(() => {
      return {
        province: data,
      }
    })
  },
  setTempBankAccount(data) {
    set(() => {
      return {
        tempBankAccount: data,
      }
    })
  },
  setErrorRejection(data) {
    set(() => {
      return {
        errorRejection: data,
      }
    })
  },
  resetData() {
    set(() => {
      return {
        personalData: DEFAULT_STATE_PERSONAL_DATA,
        activeStep: null,
        province: [],
        errorRejection: {},
      }
    })
  },
})
