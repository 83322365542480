const stateMapping = [
  {
    source: '/',
    state: 'BUSINESS_CENTER_DASHBOARD',
  },
  {
    source: '/about-us',
    state: 'ABOUT_US',
  },
  {
    source: '/dashboard/promoter-program/about',
    state: 'ABOUT_PROMOTER',
  },
  {
    source: '/dashboard/promoter-program/promote-content',
    state: 'PROMOTE_CONTENT',
  },
  {
    source: '/dashboard/promoter-program/sales-performance',
    state: 'PROMOTER_PERFORMANCE',
  },
  {
    source: '/dashboard/my-content',
    state: 'MY_PRODUCT',
  },
  {
    source: '/dashboard/my-promoter',
    state: 'MY_PROMOTER',
  },
  {
    source: '/dashboard/balance',
    state: 'MY_BALANCE',
  },
  {
    source: '/dashboard/balance/withdraw',
    state: 'WITHDRAWAL_HUB',
  },
  {
    source: '/dashboard/balance/verification',
    state: 'BANK_ACCOUNT_FILL_IN',
  },
  {
    source: '/auth/login',
    state: 'HUB_SIGN_IN',
  },
  {
    source: '/auth/verify',
    state: 'HUB_PHONE_NUMBER_VERIFICATION',
  },
  {
    source: '/dashboard/revenue-dashboard',
    state: 'REVENUE_HISTORY',
  },
  {
    source: '/dashboard/app',
    state: 'HUB_CREATOR_DASHBOARD',
  },
  {
    source: '/k/[community_id]',
    state: 'COMMUNITY_PROFILE',
  },
  {
    source: '/k/[community_id]/member',
    state: 'COMMUNITY_MEMBER',
  },
  {
    source: '/dashboard/my-community/community-setting',
    state: 'COMMUNITY_SETTING',
  },
  {
    source:
      '/dashboard/my-community/community-setting/screening-question',
    state: 'COMMUNITY_MEMBERSHIP_QUESTION',
  },
  {
    source:
      '/dashboard/my-community/community-setting/pending-members',
    state: 'COMMUNITY_MEMBER_REQUEST',
  },
  {
    source:
      '/dashboard/my-community/community-setting/screening-question/manage-question',
    state: 'EDIT_MEMBERSHIP_QUESTION',
  },
  {
    source: '/event/create',
    state: 'CREATE_EVENT',
  },
  {
    source: '/event/edit/[event_uid]',
    state: 'CREATE_EVENT',
  },
  {
    source: '/dashboard/verification',
    state: 'HUB_KYC_VERIFICATION',
  },
]

export default stateMapping
