import apiClient from 'helpers/api-client'
import { StateCreator } from 'zustand'
import { IMyBalance, IMyBalanceState } from './interface'

const resetters: (() => void)[] = []

const INITIAL_DATA: IMyBalanceState = {
  isLoadingBalance: false,
  isSuccessBalance: false,
  incomeBalance: {
    balance: 0,
    bank_verification_status: '',
    is_withdraw_eligible: false,
    withdraw_message: '',
    last_withdraw_status: '',
    is_need_agreement: false,
    is_withdraw_need_agreement: false,
    is_promoter_has_income: false,
    note_list: '',
  },
}

export const createMyBalanceSlice: StateCreator<IMyBalance> = (
  set,
) => {
  resetters.push(() => set(INITIAL_DATA))
  return {
    // states
    ...INITIAL_DATA,
    // actions
    getIncomeBalance: async () => {
      try {
        set({ isLoadingBalance: true })
        const res = await apiClient.get(
          '/wallet/hub/v1/income/balance',
        )
        if (res?.data?.code === 'SUCCESS') {
          set({
            incomeBalance: res?.data?.data,
            isSuccessBalance: true,
          })
        }
        set({ isLoadingBalance: false })
      } catch (error) {
        set({ isLoadingBalance: false, isSuccessBalance: false })
        console.error(error)
      }
    },
  }
}

export const resetMyBalanceStore = () =>
  resetters.forEach((resetter) => resetter())
