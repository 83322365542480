/**
 * Combine root path with sublink
 *
 * @param root {string}
 * @param sublink {string}
 * @returns
 */
export function path(root: string, sublink: string) {
  return `${root}${sublink}`
}
