/**
 * To check if your payload is considered a valid URL or not
 *
 * @param  {string} url The URL/Path you would like to check
 * @returns {boolean} Returns `true` if it's a valid URL, otherwise `false`
 */
const isValidUrl = (url: string): boolean => {
  try {
    new URL(url)
    return true
  } catch (e) {
    return false
  }
}

/**
 * Inject new params to your existing path, it will override existing params
 *
 * @param  {string} path              Current path, eg `/foo-bar?a=b`
 * @param  {object} params            Params to append, eg. {c: 'd', a: 'e'}
 * @param  {boolean} returnFullUrl    Return the full URL if `true`, otherwise just the path name and the query strings
 * @returns string                    Returns the new query string
 */
const getUriParam = (
  path: string,
  params: Record<string, any>,
  returnFullUrl: boolean = false,
): string => {
  const tempBase: string = 'https://tiptip.tv'
  let Url: URL

  // If valid URL is not required, but the URL is not considered valid, then prepend the given `url` with the predefined url
  if (!isValidUrl(path)) Url = new URL(path, tempBase)
  // Expected to have an else, if not it will throw an error exception
  else Url = new URL(path)

  const urlParams = new URLSearchParams(Url.search)
  for (const key in params) {
    if (params[key] !== undefined) {
      urlParams.set(key, params[key])
    }
  }
  Url.search = urlParams.toString()

  if (returnFullUrl) {
    if (isValidUrl(path)) return Url.toString()
    return Url.toString().replace(tempBase, '')
  }

  return (Url.pathname + Url.search).toString()
}

/**
 * Inject new params to your existing URL, it will override existing params
 *
 * @param  {string} url               URL you would like to refer to
 * @param  {object} params            Params to append, eg. {c: 'd', a: 'e'}
 * @returns string                    Returns the new query string
 */
const getUrlWithParams = (
  url: string,
  params: Record<string, any>,
): string => {
  return getUriParam(url, params, true)
}

/**
 * Search for query param and return the value.
 *
 * @param  {string} url               URL you would like to refer to
 * @param  {string} param            Param you would like to search for
 * @returns string                    Returns the the value of param or empty string if not found.
 */
function getQueryParam(url: string, param: string) {
  param = param.replace(/[[]/, '[').replace(/[]]/, ']')
  var regexS = '[?&]' + param + '=([^&#]*)',
    regex = new RegExp(regexS),
    results = regex.exec(url)
  if (
    results === null ||
    (results && typeof results[1] !== 'string')
  ) {
    return ''
  } else {
    return decodeURIComponent(results[1]).replace(/\W/gi, ' ')
  }
}

/**
 * To convert a given string to a valid url with https protocol
 *
 * @param {string} str
 * @returns {string}
 */
const getUrlWithProtocol = (str: string): string => {
  const pattern = new RegExp('^(http|https)://', 'i')
  const url = pattern.test(str) ? str : 'https://' + str
  return url
}

/**
 * To set full link with base url and pathname without query params
 */
const setCanonicalUrl = () => {
  const canonicalUrl =
    window.location.origin + window.location.pathname
  return canonicalUrl
}

/**
 * To get full link with base url based on pathname
 *
 * @param {string} path
 * @returns {string}
 */
const getUrlWithOrigin = (path: string): string => {
  const url = `${process.env.NEXT_PUBLIC_ALLOW_ORIGIN_URL}${path}`
  // remove double slash in url
  const validUrl = url.replace(/([^:]\/)\/+/g, '$1')
  return validUrl
}

/**
 * To set link data into branch.io
 *
 * @param  {string} feature           Feature name you would like to set, example: 'share-session', 'share-creator', etc
 * @param  {string} canonicalUrl      URL you would like to share example: 'http://tipnet.loc:3000/session/b4f967de-bbab-4108-b03c-0bed6a924498' without query params
 * @param  {string} title             Title you would like to set, example: 'Sesi karaoke bersama Tiptip admin'
 * @param  {string} imgUrl            Image URL you would like to set, example: 'https://static.tipnet.studio/content/session/f673a0e0-3a05-41d3-a698-2c605cda4a66.jpeg'
 * @param  {string} description       Description you would like to set, example: 'Bernyanyi bersama dengan suara yang merdu'
 */
const setLinkData = (
  feature: string,
  title: string,
  description: string,
  imgUrl: string,
  canonicalUrl: string,
): Object => {
  return {
    feature: feature,
    data: {
      $og_url: canonicalUrl,
      $og_title: title,
      $og_description: description,
      $og_image_url: imgUrl,
      $desktop_url: canonicalUrl,
      $android_url: canonicalUrl,
      $ios_url: canonicalUrl,
    },
  }
}

export {
  getUriParam,
  getUrlWithParams,
  getQueryParam,
  getUrlWithProtocol,
  getUrlWithOrigin,
  setCanonicalUrl,
  setLinkData,
}
