import { trackEvent, getStateFromSource } from 'helpers/analytics'
import isEmpty from 'helpers/is-empty'

export default function MyPromoterTracker(
  eventName: string,
  data?: any,
  additionalProperties?: {},
) {
  const { pathname, query, user } = data
  const state_name = getStateFromSource(pathname) || ''
  const source = isEmpty(query.source)
    ? 'unknown'
    : getStateFromSource(String(query.source))
  const source_action = isEmpty(query.source_action)
    ? 'unknown'
    : String(query.source_action)

  const baseProperties: any = {
    state_name: state_name,
    user_type: user?.role?.toLowerCase(),
  }
  const userProperties: any = {
    creator_category_id: user?.creator?.category,
    creator_category: user?.creator?.category_name,
    creator_user_id: user?.creator?.creator_user_id,
    creator_name: user?.creator?.name,
    creator_id: user?.creator?.creator_id,
  }

  const sourceProperties: any = {
    source,
    source_action,
  }

  const eventProperties: any = {
    visit_my_promoter: {
      ...sourceProperties,
      ...baseProperties,
      ...userProperties,
      ...additionalProperties,
    },
    click_my_promoter: {
      ...baseProperties,
      ...userProperties,
      ...additionalProperties,
    },
    click_join_promoter: {
      ...baseProperties,
      ...userProperties,
      ...additionalProperties,
    },
    click_leave_promoter: {
      ...baseProperties,
      ...userProperties,
      ...additionalProperties,
    },
    click_invite_supporter: {
      ...baseProperties,
      ...userProperties,
      ...additionalProperties,
    },
    click_tnc: {
      ...baseProperties,
      ...userProperties,
      ...additionalProperties,
    },
    cancel_leave_promoter: {
      ...baseProperties,
      ...userProperties,
      ...additionalProperties,
    },
    leave_promoter_completed: {
      ...baseProperties,
      ...userProperties,
      ...additionalProperties,
    },
    cancel_invite_promoter: {
      ...baseProperties,
      ...userProperties,
      ...additionalProperties,
    },
    submit_invite_promoter: {
      ...baseProperties,
      ...userProperties,
      ...additionalProperties,
    },
  }

  return trackEvent(eventName, eventProperties[eventName])
}
