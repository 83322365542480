import { trackEvent, getStateFromSource } from 'helpers/analytics'
import isEmpty from 'helpers/is-empty'

export default function WithdrawalTracker(
  eventName: string,
  data?: any,
  additionalProperties?: {},
) {
  const { pathname, query, user } = data
  const state_name = getStateFromSource(pathname) || ''
  const source = isEmpty(query.source)
    ? 'unknown'
    : getStateFromSource(String(query.source))
  const source_action = isEmpty(query.source_action)
    ? 'unknown'
    : String(query.source_action)
  const set_income_balance = true

  const baseProperties = {
    state_name: state_name,
    user_type: user?.role?.toLowerCase(),
    set_income_balance,
  }

  const sourceProperties = {
    source,
    source_action,
  }

  const eventProperties = {
    visit_withdraw_page: {
      ...sourceProperties,
      ...baseProperties,
      ...additionalProperties,
    },
    click_withdraw_amount_fill_in: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_withdraw_all_balance: {
      ...baseProperties,
      ...additionalProperties,
    },
    withdraw_continue: {
      ...baseProperties,
      ...additionalProperties,
    },
    withdraw_cancel1: {
      ...baseProperties,
      ...additionalProperties,
    },
    withdraw_cancel2: {
      ...baseProperties,
      ...additionalProperties,
    },
    submit_withdrawal: {
      ...baseProperties,
      ...additionalProperties,
    },
    withdraw_request_send: {
      ...baseProperties,
      ...additionalProperties,
    },
    close_withdraw_request_fail: {
      ...baseProperties,
      ...additionalProperties,
    },
  }

  return trackEvent(eventName, eventProperties[eventName])
}
