import _get from 'lodash/get'
import { t } from 'i18next'

const getErrorMessage = (error: Error) => {
  return _get(
    error,
    'response.data.message',
    error?.message || t('key_oops_an_error_occurred'),
  )
}

const getErrorCode = (error: Error) => {
  return _get(error, 'response.data.code', '')
}

export { getErrorCode, getErrorMessage }
