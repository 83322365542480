import * as React from 'react'
import { NextRouter } from 'next/router'

import {
  setSessionStorage,
  getSessionStorage,
  destroySessionStorage,
} from 'helpers/session-storage'
import { initLogger } from 'helpers/log'
import useAuth from 'hooks/useAuth'

import { PATH_AUTH } from 'routes/paths'
import * as Routing from 'constants/routing'

export interface PathQueryType {
  path: string
  query: object
}

export interface RoutingContextType {}

export const RoutingContext = React.createContext<RoutingContextType>(
  {},
)

export interface RoutingProps {
  children: React.ReactNode
  router: NextRouter
}

export const RoutingProvider = ({
  children,
  router,
}: RoutingProps) => {
  const { isAuthenticated, user } = useAuth()

  const storePathValues = React.useCallback(() => {
    if (
      typeof globalThis === 'undefined' ||
      !globalThis?.sessionStorage ||
      !globalThis?.location?.pathname
    ) {
      return
    }

    const prevPaths = getSessionStorage(
      Routing.PREV_PATHS,
    ) as PathQueryType[]
    const sourcePath = (prevPaths ?? []).pop()
    if (
      Routing.SKIP_SESSION_URLS.includes(globalThis.location.pathname)
    ) {
      return
    }
    // when user is not logged in
    if (!globalThis.location.pathname.includes(PATH_AUTH.login)) {
      setSessionStorage(
        Routing.CURRENT_PATH,
        globalThis.location.pathname,
      )
      setSessionStorage(Routing.PREV_PATHS, [
        { path: globalThis.location.pathname, query: {} },
      ])
      setSessionStorage(Routing.CURRENT_QUERY, router.query)
      return
    }
    if (
      sourcePath &&
      sourcePath.path === globalThis.location.pathname
    ) {
      // infinite loop detected, reset prevPath to home
      setSessionStorage(
        Routing.CURRENT_PATH,
        globalThis.location.pathname,
      )
      setSessionStorage(Routing.PREV_PATHS, prevPaths)
      setSessionStorage(Routing.CURRENT_QUERY, router.query)
      return
    }

    const prevPath = getSessionStorage(Routing.CURRENT_PATH)
    const prevQueryString = getSessionStorage(Routing.CURRENT_QUERY)
    // when user is already logged in, and current url is /login or /sign-up
    if (
      isAuthenticated &&
      Routing.SKIP_SESSION_URLS.includes(prevPath)
    ) {
      return
    }
    if (prevPath !== globalThis.location.pathname) {
      setSessionStorage(
        Routing.CURRENT_PATH,
        globalThis.location.pathname,
      )
      if (
        prevPaths &&
        !prevPaths.map((val) => val.path).includes(prevPath)
      ) {
        setSessionStorage(Routing.PREV_PATHS, [
          ...getSessionStorage(Routing.PREV_PATHS),
          { path: prevPath, query: prevQueryString ?? {} },
        ])
      }
    }
    setSessionStorage(Routing.CURRENT_QUERY, router.query)
  }, [router.query])

  React.useEffect(() => {
    initLogger({
      id: user?.user_id,
      username: user?.username,
      email: user?.email,
    })
  }, [isAuthenticated])

  React.useEffect(() => {
    // reset all history when reach homepage
    if (
      typeof globalThis !== 'undefined' &&
      globalThis?.location?.pathname === '/'
    ) {
      setSessionStorage(Routing.PREV_PATHS, [])
      setSessionStorage(
        Routing.CURRENT_PATH,
        globalThis.location.pathname,
      )
      destroySessionStorage(Routing.CURRENT_QUERY)
    }
    if (router.isReady) {
      storePathValues()
    }
  }, [router.asPath, router.isReady, storePathValues])

  return (
    <RoutingContext.Provider value={{}}>
      {children}
    </RoutingContext.Provider>
  )
}
