import { create } from 'zustand'
import { createAuthStore } from './Auth'
import { IAuth } from './Auth/interface'
import { createSupportedCountryStore } from './SupportedCountry'
import { ISupportedCountryStore } from './SupportedCountry/interface'

export const useAuthStore = create<IAuth>()((...a) => ({
  ...createAuthStore(...a),
}))

export const useSupportedCountryStore =
  create<ISupportedCountryStore>()((...a) => ({
    ...createSupportedCountryStore(...a),
  }))
