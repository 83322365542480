import { trackEvent, getStateFromSource } from 'helpers/analytics'
import isEmpty from 'helpers/is-empty'

export default function ContentTracker(
  eventName: string,
  data?,
  additionalProperties?,
) {
  const { pathname, query, user } = data
  const state_name = getStateFromSource(pathname) || ''
  const source = isEmpty(query.source)
    ? 'unknown'
    : getStateFromSource(String(query.source))
  const source_action = isEmpty(query.source_action)
    ? 'unknown'
    : String(query.source_action)

  const baseProperties = {
    state_name: state_name,
    user_type: user?.role?.toLowerCase(),
  }

  const sourceProperties = {
    source,
    source_action,
  }

  const eventProperties = {
    click_creator_dashboard: {
      ...baseProperties,
    },
    click_my_product: {
      ...baseProperties,
    },
    click_create_product: {
      ...baseProperties,
    },
    click_product_detail: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_edit_product: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_unpublish_content: {
      ...baseProperties,
      ...additionalProperties,
    },
    unpublish_product_completed: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_delete_product: {
      ...baseProperties,
      ...additionalProperties,
    },
    cancel_unpublish_product: {
      ...baseProperties,
      ...additionalProperties,
    },
    select_event: {
      ...baseProperties,
      ...additionalProperties,
    },
    select_content: {
      ...baseProperties,
      ...additionalProperties,
    },
    delete_product_completed: {
      ...baseProperties,
      ...additionalProperties,
    },
    cancel_delete_product: {
      ...baseProperties,
      ...additionalProperties,
    },
    cancel_create_product: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_create_digital_content: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_create_event: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_create_session: {
      ...baseProperties,
      ...additionalProperties,
    },
    visit_my_product: {
      ...baseProperties,
      ...sourceProperties,
    },
    visit_creator_dashboard_hub: {
      ...baseProperties,
      ...sourceProperties,
    },
    select_upload_content_on_community: {
      ...baseProperties,
      ...sourceProperties,
      ...additionalProperties,
    },
    select_upload_event_on_community: {
      ...baseProperties,
      ...sourceProperties,
      ...additionalProperties,
    },
    upload_content_on_community_completed: {
      ...baseProperties,
      ...sourceProperties,
      ...additionalProperties,
    },
    upload_event_on_community_completed: {
      ...baseProperties,
      ...sourceProperties,
      ...additionalProperties,
    },
  }

  return trackEvent(eventName, eventProperties[eventName])
}
