/* eslint-disable no-unused-vars */
export type UserProfile = {
  user_id: string
  name: string
  email: string
  phone: string
  username: string
  dob: Date
  gender: string
  country_code: string
  avatar: string
  creator: Creator | null
  preference: Preference
  updated_at: Date
  referral_code: string
  referral_codes: string[]
  referrer_code: string
}

export type Creator = {
  name: string
  category: number
  status: string
  creator_id: string
  category_name: string
  creator_user_id: string
  creator_class: CreatorClass
}

export type Preference = {
  category: string[]
}

export enum UserTypes {
  Promoter = 'Promoter',
  Creator = 'Creator',
}

export type CreatorClass = 'PERSONAL' | 'CREATOR' | 'BUSINESS'

export interface UserAccess {
  user_id: string
  role: string[]
  access: string[]
}
