import authConfig from 'configs/auth'

import { destroyCookie, getCookie, setCookie } from 'helpers/cookie'
import { destroySessionStorage } from 'helpers/session-storage'

/**
 * get id_token
 */
const getIdToken = (): string => {
  const idToken = getCookie(authConfig.idTokenName)
  return idToken
}

/**
 * set id_token
 * @param token
 */
const setIdToken = (token: string) => {
  setCookie(authConfig.idTokenName, token)
}

/**
 * destroy id_token
 */
const destroyIdToken = () => {
  // remove cookies
  destroyCookie(authConfig.idTokenName, '/')
  // remove sessions
  destroySessionStorage('userEmail')
  destroySessionStorage('inputPhoneNumber')
}

export { getIdToken, setIdToken, destroyIdToken }
