import { t } from 'i18next'

type ErrorCode =
  | 'USER_REQUEST_OTP_REACH_MAXIMUM'
  | 'FRAUD_USER_PHONE_NUMBER_DETECTED'
  | 'USER_VERIFY_OTP_REACH_MAXIMUM'
  | 'INVALID_PHONE_NUMBER_FORMAT'
  | 'TOO_MANY_REQUESTS'
  | 'USER_IS_SUSPENDED'
  | 'UNABLE_ADD_EMAIL'
  | 'SUGGEST_PHONE'
  | 'INCORRECT_USERNAME_OR_PASSWORD'
  | 'NOT_FOUND'
  | 'EMAIL_ALREADY_EXIST'
  | 'INVALID_CODE'
  | 'EXPIRED_CODE'
  | 'BAD_REQUEST'

interface IAdditionalProperties {
  seconds?: number
  defaultMessage?: string
  isLogin?: boolean
}

const renderErrorMessage = (
  code: ErrorCode,
  additionalProperties: IAdditionalProperties = {
    seconds: 0,
    defaultMessage: '',
    isLogin: false,
  },
) => {
  const { seconds, defaultMessage, isLogin } = additionalProperties

  let message = ''
  switch (code) {
    case 'BAD_REQUEST':
      message = !isLogin
        ? t('key_the_otp_code_you_entered_is_incorrect')
        : t('key_the_email_or_password_you_entered_is_incorrect')
      break
    case 'USER_REQUEST_OTP_REACH_MAXIMUM':
      const counter = seconds > 60 ? Math.ceil(seconds / 60) : seconds
      message =
        seconds > 60
          ? t(
              'key_oops_looks_like_you_exceeded_the_trial_limit_contact_us_for_help_or_try_again_to_send_the_otp_code_after_counter_minutes',
              {
                counter: counter,
              },
            )
          : t(
              'key_oops_looks_like_you_exceeded_the_trial_limit_contact_us_for_help_or_try_again_to_send_the_otp_code_after_counter_seconds',
              {
                counter: counter,
              },
            )
      break
    case 'USER_VERIFY_OTP_REACH_MAXIMUM':
      message = t(
        'key_oops_looks_like_you_exceeded_the_trial_limit_contact_us_for_help_or_try_again_to_send_the_otp_code',
      )
      break
    case 'FRAUD_USER_PHONE_NUMBER_DETECTED':
      message = t(
        'key_your_registration_information_is_potentially_unsafe_try_registering_with_a_different_phone_number',
      )
      break
    case 'INVALID_PHONE_NUMBER_FORMAT':
      message = t('key_enter_a_valid_cellphone_number')
      break
    case 'TOO_MANY_REQUESTS':
      message = t(
        'key_please_wait_seconds_seconds_and_please_try_entering_your_cellphone_number_again',
        {
          seconds: seconds,
        },
      )
      break
    case 'USER_IS_SUSPENDED':
      message = t('key_sorry_this_account_has_been_suspended')
      break
    case 'UNABLE_ADD_EMAIL':
      message = t(
        'key_sorry_at_this_time_you_cannot_change_the_email_to_your_account',
      )
      break
    case 'SUGGEST_PHONE':
      message = t(
        'key_get_an_optimal_experience_by_completing_your_cellphone_number',
      )
      break
    case 'INVALID_CODE':
      message = t(
        'key_invalid_code_make_sure_the_code_matches_and_try_again',
      )
      break
    case 'EXPIRED_CODE':
      message = t('key_the_otp_code_has_expired')
      break
    case 'INCORRECT_USERNAME_OR_PASSWORD':
      message = t('key_invalid_email_or_password_please_try_again')
      break
    case 'NOT_FOUND':
      message = t(
        'key_your_account_is_not_registered_yet_register_now',
      )
      break
    default:
      message =
        defaultMessage ||
        t('key_oops_an_error_occurred_try_again_in_a_moment')
      break
  }
  return message
}

export { renderErrorMessage }
