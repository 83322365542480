import { trackEvent, getStateFromSource } from 'helpers/analytics'
import isEmpty from 'helpers/is-empty'

export default function MyBlanceTracker(
  eventName: string,
  data?: any,
  additionalProperties?: {},
) {
  const { pathname, query, user } = data
  const state_name = getStateFromSource(pathname) || ''
  const source = isEmpty(query.source)
    ? 'unknown'
    : getStateFromSource(String(query.source))
  const source_action = isEmpty(query.source_action)
    ? 'unknown'
    : String(query.source_action)

  const baseProperties = {
    state_name: state_name,
    user_type: user?.role?.toLowerCase(),
  }
  const sourceProperties = {
    source,
    source_action,
  }

  const eventProperties = {
    visit_my_balance: {
      ...sourceProperties,
      ...baseProperties,
      ...additionalProperties,
    },
    click_my_balance: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_verify_bank_account: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_withdraw: {
      ...baseProperties,
      ...additionalProperties,
    },
  }

  return trackEvent(eventName, eventProperties[eventName])
}
