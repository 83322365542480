import { trackEvent, getStateFromSource } from 'helpers/analytics'
import isEmpty from 'helpers/is-empty'

export default function VerificationTracker(
  eventName: string,
  data?: any,
  additionalProperties?: {},
) {
  const { pathname, query, user } = data
  const state_name = getStateFromSource(pathname) || ''
  const source = isEmpty(query.source)
    ? 'unknown'
    : getStateFromSource(String(query.source))
  const source_action = isEmpty(query.source_action)
    ? 'unknown'
    : String(query.source_action)

  const baseProperties = {
    state_name: state_name,
    user_type: user?.role?.toLowerCase(),
  }

  const sourceProperties = {
    source,
    source_action,
  }

  const eventProperties = {
    visit_personal_data: {
      ...sourceProperties,
      ...baseProperties,
      ...additionalProperties,
    },
    click_fullname_fill_in: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_email_fill_in: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_phone_fill_in: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_address_fill_in: {
      ...baseProperties,
      ...additionalProperties,
    },
    back_to_my_balance: {
      ...baseProperties,
    },
    click_next_to_bank_account: {
      ...baseProperties,
      ...additionalProperties,
    },
    visit_bank_account: {
      ...baseProperties,
      ...additionalProperties,
    },
    select_bank_name: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_bank_account_fill_in: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_bank_account_name_fill_in: {
      ...baseProperties,
      ...additionalProperties,
    },
    back_to_personal_data: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_next_to_upload_document: {
      ...baseProperties,
      ...additionalProperties,
    },
    visit_upload_document: {
      ...baseProperties,
      ...additionalProperties,
    },
    select_id_card_type: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_id_card_fill_in: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_upload_id_card: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_npwp_fill_in: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_upload_npwp: {
      ...baseProperties,
      ...additionalProperties,
    },
    back_to_bank_account: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_next_to_review_document: {
      ...baseProperties,
      ...additionalProperties,
    },
    visit_review_document: {
      ...baseProperties,
      ...additionalProperties,
    },
    back_to_upload_document: {
      ...baseProperties,
      ...additionalProperties,
    },
    submit_bank_account_verification: {
      ...baseProperties,
      ...additionalProperties,
    },
  }

  return trackEvent(eventName, eventProperties[eventName])
}
