import * as Sentry from '@sentry/nextjs'
import { severityLevelFromString } from '@sentry/utils'
import sentryConfig from 'configs/sentry'
import { getCookie } from 'helpers/cookie'
import isEmpty from 'helpers/is-empty'
const sendExceptionToSentry = (error: Error) => {
  Sentry.captureException(error)
}

function initLogger(
  user = {
    id: getCookie('user_id'),
    username: getCookie('username'),
    email: getCookie('email'),
  },
): void {
  if (sentryConfig.enabled === 'true') {
    if (!isEmpty(user.id)) {
      setLogUser(user.id, user.username, user.email)
    }
  }
}

/**
 * @param {Error} error
 * @return {Void}
 */
function sendError(error: Error): void {
  if (sentryConfig.enabled === 'true') {
    sendExceptionToSentry(error)
  }
}

function setLogUser(
  userId: string,
  userName?: string,
  email?: string,
): void {
  if (sentryConfig.enabled === 'true') {
    Sentry.setUser({ id: userId, username: userName, email: email })
  }
}

function resetLogUser(): void {
  if (sentryConfig.enabled === 'true') {
    Sentry.configureScope((scope) => scope.setUser(null))
  }
}

function sendLog(
  message: string,
  level: Sentry.SeverityLevel = severityLevelFromString('info'),
): void {
  if (sentryConfig.enabled === 'true')
    Sentry.captureMessage(message, level)
}

function setTag(tagName: string, value?: string): void {
  if (sentryConfig.enabled === 'true') {
    Sentry.setTag(tagName, value)
  }
}

function addBreadcrumb(
  category: string,
  message: string,
  level: Sentry.SeverityLevel,
  data?: {
    [key: string]: any
  },
): void {
  if (sentryConfig.enabled === 'true') {
    Sentry.addBreadcrumb({
      category,
      message,
      level,
      data,
    })
  }
}
export {
  initLogger,
  sendError,
  setLogUser,
  resetLogUser,
  sendLog,
  setTag,
  addBreadcrumb,
}
