import { createReducer } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import createWebStorage from 'redux-persist/lib/storage/createWebStorage'

// ----------------------------------------------------------------------

const createNoopStorage = () => ({
  // eslint-disable-next-line no-unused-vars
  getItem(_key: string) {
    return Promise.resolve(null)
  },
  // eslint-disable-next-line no-unused-vars
  setItem(_key: string, value: any) {
    return Promise.resolve(value)
  },
  // eslint-disable-next-line no-unused-vars
  removeItem(_key: string) {
    return Promise.resolve()
  },
})

const storage =
  typeof window !== 'undefined'
    ? createWebStorage('local')
    : createNoopStorage()

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
}
const placeholder = createReducer({ placeholder: 0 }, () => {})
const rootReducer = combineReducers({ placeholder })

export { rootPersistConfig, rootReducer }
