import { ClientJS } from 'clientjs/dist/client.base.min'
import {
  getSessionStorage,
  setSessionStorage,
} from 'helpers/session-storage'
import isEmpty from 'helpers/is-empty'
import { getLatitudeLongitude } from 'helpers/navigator'
import ipstackConfig from 'configs/ipstack'
import axios from 'axios'
import { sendLog, setTag } from 'helpers/log'
import { setDefaultProperties } from 'helpers/analytics'

function setUserDevice(): void {
  interface IUserDevice {
    latitude?: number
    longitude?: number
    ip?: string
  }

  const apiClient = axios.create({
    baseURL: ipstackConfig.baseUrl,
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
    params: {
      access_key: ipstackConfig.accessKey,
    },
  })

  Promise.all([
    getLatitudeLongitude(),
    new Promise((resolve) => {
      apiClient
        .get('')
        .then((res) => {
          setSessionStorage('ip', res?.data?.ip)
          setTag('isp', res?.data?.connection?.isp || '')
          resolve(res?.data)
        })
        .catch((error) => sendLog(error))
    }),
  ])
    .then((res) => {
      const responseLocation: IUserDevice = res[0] // response from navigator.geolocation.currentPosition
      const responseIpAddress: IUserDevice = res[1] // response from ipstack
      // set super property to mixpanel
      setDefaultProperties({
        // default set latitude from navigator, if null will assign from ipstack
        latitude:
          responseLocation?.latitude ||
          responseIpAddress?.latitude ||
          null,
        // default set longitude from navigator, if null will assign from ipstack
        longitude:
          responseLocation?.longitude ||
          responseIpAddress?.longitude ||
          null,
        ip_address: responseIpAddress?.ip || null, // collect IP Address from ipstack
        unique_device_id: getFingerprint(), // fingerprint for device id
      })
    })
    .catch((error) => sendLog(error))
}

function getFingerprint(): string {
  const fp = getSessionStorage('fp') // fingerprint that stored on session storage

  if (isEmpty(fp) && typeof window === 'object') {
    const client = new ClientJS()
    const fingerprint = client.getFingerprint()
    setSessionStorage('fp', fingerprint)
    return fingerprint
  }

  return fp
}

export { getFingerprint, setUserDevice }
