import { trackEvent, getStateFromSource } from 'helpers/analytics'
import { getDateTimeUTC } from 'helpers/date-time'
import isEmpty from 'helpers/is-empty'
import { getLocalStorage } from 'helpers/local-storage'
import { getSessionStorage } from 'helpers/session-storage'
import { DeliveryMethod } from '../store/Auth/interface'

export default function AuthTracker(
  eventName: string,
  data?: any,
  additionalProperties?: {},
) {
  const { pathname, query, currentTab, verificationMethod } = data
  const state_name = getStateFromSource(pathname) || ''
  const source = isEmpty(query.source)
    ? 'unknown'
    : getStateFromSource(String(query.source))
  const source_action = isEmpty(query.source_action)
    ? 'unknown'
    : String(query.source_action)

  const baseProperties: any = {
    state_name: state_name,
  }
  // eslint-disable-next-line no-unused-vars
  const sourceProperties: any = {
    source,
    source_action,
  }

  const state_sub_name: string = currentTab
  const verificationProperties: any = {
    ...baseProperties,
    state_sub_name,
  }
  const phone_verification_method: DeliveryMethod = isEmpty(
    verificationMethod,
  )
    ? getLocalStorage('verificationMethod')?.method || 'WA'
    : verificationMethod

  const authMethod = getSessionStorage('authMethod')
  const auth_datetime = getDateTimeUTC(new Date().toISOString())

  const eventProperties: any = {
    visit_sign_in: {
      ...baseProperties,
      ...sourceProperties,
      state_sub_name,
    },
    click_sign_up: {
      ...baseProperties,
      state_sub_name,
    },
    select_email_sign_in_tab: {
      ...baseProperties,
      state_sub_name,
    },
    select_phone_sign_in_tab: {
      ...baseProperties,
      state_sub_name,
    },
    click_google_sign_in: {
      ...baseProperties,
      state_sub_name,
    },
    click_verification_method_wa: {
      ...verificationProperties,
      ...additionalProperties,
      phone_verification_method: 'SMS',
    },
    click_verification_method_sms: {
      ...verificationProperties,
      ...additionalProperties,
      phone_verification_method: 'WA',
    },
    cancel_choose_verification_method: {
      ...verificationProperties,
      ...additionalProperties,
    },
    visit_hub_phone_number_verification: {
      ...baseProperties,
      ...sourceProperties,
      phone_verification_method,
    },
    trigger_verified_phone: {
      ...baseProperties,
      phone_verification_method,
      ...additionalProperties,
    },
    trigger_resend_phone_verification: {
      ...verificationProperties,
      phone_verification_method,
    },
    sign_in_completed: {
      state_name:
        authMethod === 'phone_number'
          ? baseProperties.state_name
          : 'HUB_SIGN_IN',
      sign_in_method: authMethod,
      phone_verification_method:
        authMethod === 'phone_number'
          ? verificationMethod
          : undefined,
      set_coin_balance: true,
      set_income_balance: true,
      login_datetime: auth_datetime,
    },
  }

  return trackEvent(eventName, eventProperties[eventName])
}
