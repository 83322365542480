import { useQuery } from '@tanstack/react-query'
import { getStatusKYCTier3 } from '~/services/user'

const useUserKYCTier3 = () => {
  return useQuery({
    queryKey: ['user-kyc-tier-3'],
    queryFn: async () => {
      try {
        const response = await getStatusKYCTier3()
        return response?.data?.data
      } catch (error) {
        throw error
      }
    },
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export { useUserKYCTier3 }
