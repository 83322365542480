// global styling
import '../style.css'

// i18n
import '../locales/i18n'

// scroll bar
import 'simplebar/src/simplebar.css'

// editor
import 'react-quill/dist/quill.snow.css'

// slick-carousel
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'

import { ReactElement, ReactNode } from 'react'
import { useEffect } from 'react'
// next
import { NextPage } from 'next'
import Head from 'next/head'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Script from 'next/script'
//
import { Provider as ReduxProvider } from 'react-redux'
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
// redux
import { store } from '../redux/store'
// utils
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
// contexts
import { AuthProvider } from '~/contexts/AuthContext'
import { SettingsProvider } from '~/contexts/SettingsContext'
import { CollapseDrawerProvider } from '~/contexts/CollapseDrawerContext'
import { ProductPopupProvider } from '~/contexts/ProductPopupContext'
// theme
import ThemeProvider from '../theme'
// components
import { SettingsValueProps } from 'components/settings/type'
import ProgressBar from 'components/ProgressBar'
import NotistackProvider from 'components/NotistackProvider'
import MotionLazyContainer from 'components/animate/MotionLazyContainer'

import { pageview } from 'helpers/google-tag-manager'
import { GTM_ID } from 'constants/index'
import { initAnalytics } from 'helpers/analytics'
import { RoutingProvider } from '~/contexts/RoutingContext'
import {
  FlagProvider,
  UnleashClient,
} from '@unleash/proxy-client-react'
import flagConfig from 'configs/feature-flag'
initAnalytics()

type NextPageWithLayout = NextPage & {
  // eslint-disable-next-line no-unused-vars
  getLayout?: (page: ReactElement) => ReactNode
}

interface MyAppProps extends AppProps {
  settings: SettingsValueProps
  Component: NextPageWithLayout
}

export default function MyApp(props: MyAppProps) {
  const router = useRouter()
  const queryClient = new QueryClient()
  const { Component, pageProps } = props

  const settings: SettingsValueProps = {
    themeMode: 'light',
    themeDirection: 'ltr',
    themeContrast: 'default',
    themeLayout: 'horizontal',
    themeColorPresets: 'default',
    themeStretch: false,
  }

  const getLayout = Component.getLayout ?? ((page) => page)
  //clients unleash config
  const client = new UnleashClient(flagConfig)

  useEffect(() => {
    router.events.on('routeChangeComplete', pageview)
    return () => {
      router.events.off('routeChangeComplete', pageview)
    }
  }, [router.events])
  return (
    <div>
      <Head>
        <meta
          name="viewport"
          content="initial-scale=1, width=device-width"
        />
        <title>TipTip Content Hub</title>
      </Head>
      <Script
        id="Freshchat-js-sdk"
        src="https://wchat.freshchat.com/js/widget.js"
        async
      />
      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${GTM_ID}');
              `,
        }}
      />

      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ReduxProvider store={store}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <RoutingProvider router={router}>
                <CollapseDrawerProvider>
                  <SettingsProvider defaultSettings={settings}>
                    <MotionLazyContainer>
                      <ThemeProvider>
                        <NotistackProvider>
                          <ProgressBar />
                          <FlagProvider
                            unleashClient={client}
                            startClient={false}
                          >
                            <ProductPopupProvider>
                              {getLayout(
                                <Component {...pageProps} />,
                              )}
                            </ProductPopupProvider>
                          </FlagProvider>
                        </NotistackProvider>
                      </ThemeProvider>
                    </MotionLazyContainer>
                  </SettingsProvider>
                </CollapseDrawerProvider>
              </RoutingProvider>
            </LocalizationProvider>
          </ReduxProvider>
        </AuthProvider>
      </QueryClientProvider>
    </div>
  )
}

// ----------------------------------------------------------------------

// MyApp.getInitialProps = async (context: AppContext) => {
//   const appProps = await App.getInitialProps(context)

//   const cookies = cookie.parse(
//     context.ctx.req
//       ? context?.ctx?.req?.headers?.cookie || ''
//       : document?.cookie,
//   )

//   const settings = getSettings(cookies)

//   return {
//     ...appProps,
//     settings,
//   }
// }
