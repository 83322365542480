import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
// @mui
import {
  Box,
  Dialog,
  Divider,
  Typography,
  Stack,
  IconButton,
} from '@mui/material'
// components
import Iconify from 'components/Iconify'
// constants
import { DEEPLINK_URL } from 'constants/index'
// hooks
import useAuth from 'hooks/useAuth'
// helpers and others
import trackEvent from '~/trackers'
import useLocales from 'hooks/useLocales'

// ----------------------------------------------------------------------

const CREATE_DIGITAL_CONTENT_URL = '/dashboard/create-content'
const CREATE_LIVE_SESSION_URL = `${DEEPLINK_URL}/CreateSession`
const CREATE_EVENT_URL = '/event/create'

export default function ProductPopUp({
  isOpenProductModal,
  openProductModal,
}) {
  const { t } = useLocales()
  const { push, pathname, query } = useRouter()
  const { user } = useAuth()
  const trackerData = { pathname, query, user }

  const [isDismiss, setDismiss] = useState(false)

  const baseProperties = {
    modal_name: 'CREATE_PRODUCT',
  }

  const handleCloseModal = () => {
    setDismiss(true)
    openProductModal(false)
  }

  const handleCreateDigitalContent = () => {
    trackEvent.content('click_create_digital_content', trackerData, {
      ...baseProperties,
    })
    setDismiss(false)
    openProductModal(false)
    push(CREATE_DIGITAL_CONTENT_URL)
  }

  const handleCreateEvent = () => {
    trackEvent.content('click_create_event', trackerData, {
      ...baseProperties,
    })
    setDismiss(false)
    openProductModal(false)
    push(
      `${CREATE_EVENT_URL}?source=${pathname}&source_action=click_create_event`,
    )
  }

  const handleCreateSession = () => {
    trackEvent.content('click_create_session', trackerData, {
      ...baseProperties,
    })
    setDismiss(false)
    openProductModal(false)
    window.open(CREATE_LIVE_SESSION_URL, '_blank')
  }

  const createProduct = ({
    icon,
    iconBackgroundColor,
    title,
    description,
    onClick,
  }) => {
    return (
      <Stack
        direction="row"
        spacing={2}
        sx={{ cursor: 'pointer' }}
        onClick={onClick}
      >
        <Box
          sx={{
            minWidth: '24px',
            height: '24px',
            background: iconBackgroundColor,
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Iconify icon={icon} color="white" width={18} height={18} />
        </Box>
        <Stack spacing={1}>
          <Typography
            sx={(theme) => ({
              ...theme.typography.subtitle1,
              fontWeight: '700',
            })}
          >
            {title}
          </Typography>
          <Typography
            sx={(theme) => ({
              ...theme.typography.body1,
            })}
          >
            {description}
          </Typography>
        </Stack>
      </Stack>
    )
  }

  useEffect(() => {
    if (isOpenProductModal) setDismiss(true)
  }, [isOpenProductModal])

  useEffect(() => {
    if (isDismiss && !isOpenProductModal) {
      trackEvent.content('cancel_create_product', trackerData, {
        ...baseProperties,
      })
    }
  }, [isDismiss, isOpenProductModal])

  return (
    <Dialog open={isOpenProductModal} onClose={handleCloseModal}>
      <Box
        style={{
          padding: '20px',
          minWidth: '500px',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography sx={(theme) => ({ ...theme.typography.h5 })}>
            {t('key_create_product')}
          </Typography>
          <IconButton
            size="medium"
            onClick={() => openProductModal(false)}
          >
            <Iconify icon="material-symbols:close" />
          </IconButton>
        </Stack>
        <Stack sx={{ paddingTop: '20px' }} spacing={2}>
          {createProduct({
            icon: 'material-symbols:drive-folder-upload',
            iconBackgroundColor:
              'linear-gradient(270deg, #F87500 0%, #FFA235 100%)',
            title: t('key_create_digital_content'),
            description: t('key_create_content_desc'),
            onClick: handleCreateDigitalContent,
          })}
          <Divider variant="middle" />
          {createProduct({
            icon: 'material-symbols:video-call',
            iconBackgroundColor:
              'linear-gradient(270deg, #484FF9 0%, #A8ABFE 100%)',
            title: t('key_create_live_session'),
            description: t('key_create_session_desc'),
            onClick: handleCreateSession,
          })}
          <Divider variant="middle" />
          {createProduct({
            icon: 'material-symbols:event-note',
            iconBackgroundColor:
              'linear-gradient(270deg, #3B95FF 0%, #95CBFF 100%)',
            title: t('key_create_event'),
            description: t('key_create_event_desc'),
            onClick: handleCreateEvent,
          })}
        </Stack>
      </Box>
    </Dialog>
  )
}
