import { FormBecomeCreatorValuesProps } from 'commons/containers/FillProfileDialog'
import { AddressType } from 'domains/Users/Verification/stores/VerificationUser/interface'
import apiClient from 'helpers/api-client'

export interface IAddressParams {
  type: AddressType
  province?: string
  district?: string
  sub_district?: string
  village?: string
}

export const getAddressData = async (params: IAddressParams) => {
  return await apiClient({
    url: `/user/hub/v1/address`,
    method: 'GET',
    params,
  })
}

export const getKYC = async () => {
  return await apiClient({
    url: '/user/hub/v1/kyc',
    method: 'GET',
  })
}

export const postPresignURL = async ({ target, filename }) => {
  return await apiClient({
    url: '/user/hub/v1/presign-url',
    method: 'POST',
    data: {
      target,
      ext:
        '.' +
        filename
          .substring(filename.lastIndexOf('.') + 1)
          .toLowerCase(),
    },
  })
}

export const putPersonalDataKYC = async (data) => {
  return await apiClient({
    url: '/user/hub/v1/kyc/personal',
    method: 'PUT',
    data,
  })
}

export const putBankAccountKYC = async (data) => {
  return await apiClient({
    url: '/user/hub/v1/kyc/bank',
    method: 'PUT',
    data,
  })
}

export const putNpwpKYC = async (data) => {
  return await apiClient({
    url: '/user/hub/v1/kyc/npwp',
    method: 'PUT',
    data,
  })
}

export const submitKYC = async (data) => {
  return await apiClient({
    url: '/user/hub/v1/kyc/submit',
    method: 'PUT',
    data,
  })
}

export const getStatusKYCTier3 = async () => {
  return await apiClient({
    url: '/user/hub/v1/kyc/tier3',
    method: 'GET',
  })
}

export const submitKYCTier3 = async () => {
  return await apiClient({
    url: '/user/hub/v1/kyc/tier3',
    method: 'POST',
  })
}

export const postCreateRole = async (
  payload: FormBecomeCreatorValuesProps,
) => {
  return await apiClient({
    url: '/user/hub/v1/creator',
    method: 'POST',
    data: {
      ...payload,
      category_id: Number(payload.category_id),
    },
  })
}

export const getSupportedCountries = async () => {
  return await apiClient({
    url: '/user/guest/v1/supported-countries',
    method: 'GET',
  })
}
