import { trackEvent, getStateFromSource } from 'helpers/analytics'
import isEmpty from 'helpers/is-empty'
import { UserTypes } from '~/@types/profile'

export default function EventTracker(
  eventName: string,
  data?,
  additionalProperties = {},
) {
  const { pathname, query, user = {}, state_sub_name } = data
  const state_name = getStateFromSource(pathname) || ''
  const source = isEmpty(query.source)
    ? 'unknown'
    : getStateFromSource(String(query.source))
  const source_action = isEmpty(query.source_action)
    ? 'unknown'
    : String(query.source_action)

  const sourceProperties = {
    source,
    source_action,
  }
  const userProperties = {
    user_type:
      user?.role === UserTypes.Creator ? 'creator' : 'supporter',
    creator_user_id: user?.creator?.creator_user_id,
    creator_name: user?.creator?.name,
    creator_id: user?.creator?.creator_id,
    creator_category_id: user?.creator?.category,
    creator_category: user?.creator?.category_name,
  }

  function getEventProperties(eventName) {
    switch (eventName) {
      case 'visit_create_event':
        return {
          state_name,
          state_sub_name: state_sub_name || '',
          ...userProperties,
          ...sourceProperties,
          ...additionalProperties,
        }
      default:
        return {
          state_name,
          state_sub_name: state_sub_name || '',
          ...userProperties,
          ...additionalProperties,
        }
    }
  }

  return trackEvent(eventName, getEventProperties(eventName))
}
