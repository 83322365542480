import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { ICommunitySetting } from './CommunitySetting/interface'
import { createCommunitySettingSlice } from './CommunitySetting'

export const useBoundCommunityStore = create<ICommunitySetting>()(
  persist(
    (...a) => ({
      ...createCommunitySettingSlice(...a),
    }),
    {
      name: 'community-info',
      partialize: (state) => ({ communityInfo: state.communityInfo }),
    },
  ),
)
