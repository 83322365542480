import { StateCreator } from 'zustand'
import { IVerificationCoin, IKYCTierThree } from './interface'

export const DEFAULT_STATE_COIN_TRANSFER_AGREEMENT: IKYCTierThree = {
  address: '',
  is_employment_agreement: false,
  is_money_laundry_agreement: false,
  is_transfer_coins_agreement: false,
}

export const createVerificationCoinSlice: StateCreator<
  IVerificationCoin
> = (set) => ({
  dataForm: DEFAULT_STATE_COIN_TRANSFER_AGREEMENT,
  setDataForm(data) {
    set(() => {
      return {
        dataForm: data,
      }
    })
  },
})
