import { trackEvent, getStateFromSource } from 'helpers/analytics'
import isEmpty from 'helpers/is-empty'
import { UserTypes } from '~/@types/profile'

export default function CommunityTracker(
  eventName: string,
  data?,
  additionalProperties?,
) {
  const { pathname, query, community, user = {} } = data
  const state_name = getStateFromSource(pathname) || ''
  const source = isEmpty(query.source)
    ? 'unknown'
    : getStateFromSource(String(query.source))
  const source_action = isEmpty(query.source_action)
    ? 'unknown'
    : String(query.source_action)

  const sourceProperties = {
    source,
    source_action,
  }
  const userProperties = {
    user_type:
      user?.role === UserTypes.Creator ? 'creator' : 'supporter',
    creator_user_id: user?.creator?.creator_user_id,
    creator_name: user?.creator?.name,
    creator_id: user?.creator?.creator_id,
  }
  const communityProperties = isEmpty(community)
    ? {}
    : {
        community_id: community.community_id,
        community_name: community.name,
        community_description: community.description,
        community_category_id: community.category.id,
        community_category: community.category.name,
        community_owner_user_id: community.owner_id,
        community_tagging: community.community_tagging,
        is_owner_community: community.is_owner,
        is_member_community: community.is_member,
        community_is_private: community.is_private,
      }

  const eventProperties = {
    visit_community_setting: {
      state_name,
      ...userProperties,
      ...sourceProperties,
      ...communityProperties,
    },
    click_turn_on_private_community: {
      state_name,
      ...userProperties,
      ...communityProperties,
    },
    click_turn_off_private_community: {
      state_name,
      ...userProperties,
      ...communityProperties,
    },
    click_view_community_member_request: {
      state_name,
      ...userProperties,
      ...communityProperties,
    },
    click_membership_question: {
      state_name,
      ...userProperties,
      ...communityProperties,
    },
    visit_community_membership_question: {
      state_name,
      ...sourceProperties,
      ...userProperties,
      ...communityProperties,
    },
    click_add_membership_question: {
      state_name,
      ...userProperties,
      ...communityProperties,
    },
    visit_edit_membership_question: {
      state_name,
      ...userProperties,
      ...sourceProperties,
      ...communityProperties,
    },
    submit_community_membership_question: {
      state_name,
      ...userProperties,
      ...communityProperties,
    },
    delete_community_membership_question: {
      state_name,
      ...additionalProperties,
      ...userProperties,
      ...communityProperties,
    },
    visit_community_member_request: {
      state_name,
      ...userProperties,
      ...sourceProperties,
      ...communityProperties,
      ...additionalProperties,
    },
    approve_member_request: {
      state_name,
      ...userProperties,
      ...communityProperties,
      ...additionalProperties,
    },
    click_reject_member_request: {
      state_name,
      ...userProperties,
      ...communityProperties,
      ...additionalProperties,
    },
    click_member_request_response: {
      state_name,
      ...userProperties,
      ...communityProperties,
      ...additionalProperties,
    },
    reject_member_request: {
      state_name,
      ...userProperties,
      ...communityProperties,
      ...additionalProperties,
    },
  }

  return trackEvent(eventName, eventProperties[eventName])
}
