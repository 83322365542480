/* eslint-disable no-unused-vars */
export default function sendLog(error: any) {
  let code = undefined
  let message = error.message ?? 'Unkown error'

  // Check if it return a response
  if (error.response) {
    // Check if the response include a code, if it is, consider this as a Backend error which should not be logged
    if (error.response.data?.code) return false

    // Otherwise, if is not a Backend error, let's continue
    if (error.response.status) code = error.response.status
    if (error.response.data?.message)
      message = error.response.data.message
  }

  // Formats endpoint
  const endpoint = `[${(
    error.config?.method ?? '?'
  ).toUpperCase()}] ${error.config?.url ?? '?'}`

  // Formats code
  code = `[${code ?? '?'}]`

  // Send to Log helper
  // sendLog(
  //   `Request to ${endpoint} failed with error: ${code} ${message}`,
  // )

  return true
}
