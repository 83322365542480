import { useTranslation, Trans } from 'react-i18next'
import useSettings from './useSettings'
// config
import { allLangs, defaultLang } from '../config'
import { getLocalStorage } from 'helpers/local-storage'

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t } = useTranslation()

  const { onChangeDirectionByLang } = useSettings()

  const langStorage =
    typeof window !== 'undefined' ? getLocalStorage('i18nextLng') : ''

  const currentLang =
    allLangs.find((_lang) => _lang.value === langStorage) ||
    defaultLang

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang)
    onChangeDirectionByLang(newlang)
  }

  return {
    onChangeLang: handleChangeLanguage,
    t: (text: any, options?: any) => t(text, options),
    currentLang,
    allLangs,
    Trans,
  }
}
