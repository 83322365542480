import mixpanel from 'mixpanel-browser'
import mixPanelConfig from 'configs/mixpanel'
import isEmpty from 'helpers/is-empty'
import stateMapping from 'constants/state-mapping'
import { getCookie } from 'helpers/cookie'
import { getQueryParam } from 'helpers/url'
import whitelistedEvents from 'constants/tracker-whitelist'

function initAnalytics(): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true'
  ) {
    mixpanel.init(mixPanelConfig.token as string, {
      ignore_dnt: true,
      debug: mixPanelConfig.debug === 'true',
      persistence: 'localStorage',
      loaded: function (mixpanel) {
        // eslint-disable-next-line no-console
        console.log('Mixpanel successfully loaded')
        // eslint-disable-next-line no-console
        console.log('distinct id : ', mixpanel.get_distinct_id())

        // do identify for logged in user
        const userId = getCookie('user_id')
        if (!isEmpty(userId)) {
          mixpanel.identify(userId)
        }
        campaignParams()
      },
    })
  }
}

function trackEvent(
  eventName: string,
  eventProperties?: Object,
): void {
  //whitelisted events
  if (
    whitelistedEvents.includes(eventName) &&
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixPanelConfig.token
  ) {
    mixpanel.track(eventName, eventProperties, {
      send_immediately: true,
    })
  }
}

function campaignParams() {
  var campaign_keywords =
      'utm_source utm_medium utm_campaign utm_content utm_term'.split(
        ' ',
      ),
    keyword = '',
    params: { [key: string]: any } = {},
    first_params: { [key: string]: any } = {}
  var index
  for (index = 0; index < campaign_keywords.length; ++index) {
    keyword = getQueryParam(document.URL, campaign_keywords[index])
    if (keyword.length) {
      params[campaign_keywords[index] + ' [last touch]'] = keyword
    }
  }
  for (index = 0; index < campaign_keywords.length; ++index) {
    keyword = getQueryParam(document.URL, campaign_keywords[index])
    if (keyword.length) {
      first_params[campaign_keywords[index] + ' [first touch]'] =
        keyword
    }
  }
  mixpanel.people.set(params)
  mixpanel.people.set_once(first_params)
  mixpanel.register(params)
}

function getStateFromSource(source: string): string {
  const index = stateMapping.findIndex(
    (state) => state?.source === source,
  )
  return isEmpty(stateMapping[index]?.state)
    ? ''
    : stateMapping[index].state
}

function incrementPeopleProperties(properties: Object): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixPanelConfig.token
  ) {
    mixpanel.people.increment(properties)
  }
}

function setOncePeopleProperties(properties: Object): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixPanelConfig.token
  ) {
    mixpanel.people.set_once(properties)
  }
}

function setPeopleProperties(properties: Object): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixPanelConfig.token
  ) {
    mixpanel.people.set(properties)
  }
}

function setDefaultProperties(properties?: Object): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixPanelConfig.token
  ) {
    mixpanel.register(properties)
  }
}

function setUserLogin(userId: string, properties?: Object): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixPanelConfig.token
  ) {
    mixpanel.identify(userId)
    mixpanel.people.set(properties)
  }
}

export {
  initAnalytics,
  trackEvent,
  getStateFromSource,
  incrementPeopleProperties,
  setOncePeopleProperties,
  setPeopleProperties,
  setDefaultProperties,
  setUserLogin,
}
