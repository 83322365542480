import { trackEvent, getStateFromSource } from 'helpers/analytics'
import isEmpty from 'helpers/is-empty'

export default function UserTracker(
  eventName: string,
  data?: any,
  additionalProperties?: {},
) {
  const { pathname, query, user } = data
  const state_name = getStateFromSource(pathname) || ''
  const source = isEmpty(query.source)
    ? 'unknown'
    : getStateFromSource(String(query.source))
  const source_action = isEmpty(query.source_action)
    ? 'unknown'
    : String(query.source_action)

  const baseProperties: any = {
    state_name: state_name,
    user_type: user?.role?.toLowerCase(),
  }
  // eslint-disable-next-line no-unused-vars
  const sourceProperties: any = {
    source,
    source_action,
  }

  const eventProperties: any = {
    trigger_avatar_drop_down: {
      ...baseProperties,
    },
    trigger_logout: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_verify_kyc: {
      ...baseProperties,
    },
    visit_kyc_verification: {
      ...baseProperties,
      ...sourceProperties,
    },
    click_tnc: {
      ...baseProperties,
    },
    visit_personal_data: {
      ...baseProperties,
      state_sub_name: 'PERSONAL_DATA',
    },
    click_fullname_fill_in: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_address_fill_in: {
      ...baseProperties,
      state_sub_name: 'PERSONAL_DATA',
    },
    click_residence_address_fill_in: {
      ...baseProperties,
      state_sub_name: 'PERSONAL_DATA',
    },
    select_id_card_type: {
      ...baseProperties,
      state_sub_name: 'PERSONAL_DATA',
      ...additionalProperties,
    },
    click_upload_id_card: {
      ...baseProperties,
      state_sub_name: 'PERSONAL_DATA',
    },
    click_selfie_with_id_card: {
      ...baseProperties,
      state_sub_name: 'PERSONAL_DATA',
    },
    click_id_card_fill_in: {
      ...baseProperties,
      state_sub_name: 'PERSONAL_DATA',
    },
    visit_bank_account: {
      ...baseProperties,
      state_sub_name: 'BANK_ACCOUNT',
    },
    select_bank_name: {
      ...baseProperties,
      state_sub_name: 'BANK_ACCOUNT',
      ...additionalProperties,
    },
    click_bank_account_fill_in: {
      ...baseProperties,
      state_sub_name: 'BANK_ACCOUNT',
    },
    click_bank_account_name_fill_in: {
      ...baseProperties,
      state_sub_name: 'BANK_ACCOUNT',
    },
    visit_npwp: {
      ...baseProperties,
      ...sourceProperties,
      source: query?.source ? 'BANK_ACCOUNT' : 'unknown',
      state_sub_name: 'UPLOAD_DOCUMENT',
    },
    click_npwp_satus: {
      ...baseProperties,
      state_sub_name: 'UPLOAD_DOCUMENT',
      ...additionalProperties,
    },
    click_npwp_type: {
      ...baseProperties,
      state_sub_name: 'UPLOAD_DOCUMENT',
      ...additionalProperties,
    },
    click_npwp_fill_in: {
      ...baseProperties,
      state_sub_name: 'UPLOAD_DOCUMENT',
    },
    click_upload_npwp: {
      ...baseProperties,
      state_sub_name: 'UPLOAD_DOCUMENT',
    },
    visit_review_document: {
      ...baseProperties,
      ...sourceProperties,
      source: query?.source ? 'UPLOAD_DOCUMENT' : 'unknown',
    },
    click_tnc_agreement: {
      ...baseProperties,
      state_sub_name: 'REVIEW_DOCUMENT',
    },
    submit_kyc_verification: {
      ...baseProperties,
      state_sub_name: 'REVIEW_DOCUMENT',
      ...additionalProperties,
    },
  }

  return trackEvent(eventName, eventProperties[eventName])
}
