import { useQuery } from '@tanstack/react-query'
import { useBoundCommunityStore } from 'domains/Community/store'
import { defaultStateCommunityInfo } from 'domains/Community/store/CommunitySetting'
import {
  getCommunityInfo,
  getCommunityPendingMembers,
  getScreeningQuestion,
  getScreeningQuestionAnswer,
} from '~/services/social'

const DEFAULT_LIMIT = 10

const useCommunityInfo = () => {
  const { setCommunityInfo } = useBoundCommunityStore((state) => ({
    setCommunityInfo: state.setCommunityInfo,
  }))
  return useQuery({
    queryKey: ['community-info'],
    queryFn: async () => {
      try {
        const response = await getCommunityInfo()
        return response.data
      } catch (error) {
        setCommunityInfo({
          ...defaultStateCommunityInfo,
          isError: true,
          errorCode: error?.code,
        })
        throw error
      }
    },
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setCommunityInfo({ ...data, isError: false, errorCode: '' })
      return data
    },
  })
}

const useCommunityPendingMembers = (id: string, params = {}) => {
  const { setTotalPendingMembers } = useBoundCommunityStore(
    (state) => ({
      setTotalPendingMembers: state.setTotalPendingMembers,
    }),
  )
  return useQuery({
    queryKey: ['community-pending-members', id, params],
    queryFn: async () => {
      try {
        const response = await getCommunityPendingMembers(id, {
          limit: DEFAULT_LIMIT,
          ...params,
        })
        if (response.data.code === 'SUCCESS')
          setTotalPendingMembers(response.data._metadata.total)
        return response.data
      } catch (error) {
        throw error
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: false,
  })
}

const useCommunityScreeningQuestion = (communityId: string) => {
  const { setScreeningQuestionData } = useBoundCommunityStore(
    (state) => ({
      setScreeningQuestionData: state.setScreeningQuestionData,
    }),
  )
  return useQuery({
    queryKey: ['community-screening-questions', communityId],
    queryFn: async () => {
      try {
        const response = await getScreeningQuestion(communityId)
        if (response.data.code === 'SUCCESS' && response.data.data)
          setScreeningQuestionData(response.data.data)
        return response.data
      } catch (error) {
        throw error
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: false,
  })
}

const useCommunityScreeningQuestionAnswer = ({
  communityId,
  userId,
}: {
  communityId: string
  userId: string
}) => {
  const { setScreeningQuestionAnswer, resetScreeningQuestionAnswer } =
    useBoundCommunityStore((state) => ({
      setScreeningQuestionAnswer: state.setScreeningQuestionAnswer,
      resetScreeningQuestionAnswer:
        state.resetScreeningQuestionAnswer,
    }))
  return useQuery({
    queryKey: ['community-screening-question-answer', communityId],
    queryFn: async () => {
      resetScreeningQuestionAnswer()
      try {
        const response = await getScreeningQuestionAnswer(
          communityId,
          userId,
        )
        if (response.data.code === 'SUCCESS' && response.data.data)
          setScreeningQuestionAnswer(response.data.data)
        return response.data
      } catch (error) {
        throw error
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: false,
  })
}

export {
  useCommunityInfo,
  useCommunityPendingMembers,
  useCommunityScreeningQuestion,
  useCommunityScreeningQuestionAnswer,
}
