const setLocalStorage = (
  key: string,
  value: string | number | object | Array<any>,
): void => {
  if (
    typeof globalThis === 'undefined' ||
    !globalThis?.localStorage
  ) {
    return
  }
  localStorage.setItem(key, JSON.stringify(value))
}

const getLocalStorage = (key: string): any => {
  if (
    typeof globalThis === 'undefined' ||
    !globalThis?.localStorage
  ) {
    return undefined
  }
  const storageValue = localStorage.getItem(key)
  if (
    storageValue === null ||
    storageValue === undefined ||
    storageValue === 'undefined'
  )
    return undefined
  try {
    var o = JSON.parse(storageValue)
    if (o && typeof o === 'object') {
      return o
    }
  } catch (e) {}
  return storageValue
}

const destroyLocalStorage = (key: string): void => {
  if (
    typeof globalThis === 'undefined' ||
    !globalThis?.localStorage
  ) {
    return
  }
  localStorage.removeItem(key)
}

const clearLocalStorageExcept = (whitelist: Array<string>) => {
  const keysToRemove: string[] = []

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)

    if (key && !whitelist.includes(key)) {
      keysToRemove.push(key)
    }
  }

  keysToRemove.forEach((key) => localStorage.removeItem(key))
}

const removeElementLocalStorage = (key: string, name: string) => {
  let elements = getLocalStorage(key)
  elements = elements.filter((element) => element !== name)
  localStorage.setItem(key, JSON.stringify(elements))
}

const setWithExpiry = (key, value, time) => {
  const item = {
    value: value,
    expiry: time,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key)

  if (!itemStr) {
    return null
  }

  const item = JSON.parse(itemStr)
  const now = new Date()

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key)
    return null
  }

  return item.value
}

export {
  setLocalStorage,
  destroyLocalStorage,
  clearLocalStorageExcept,
  getLocalStorage,
  removeElementLocalStorage,
  setWithExpiry,
  getWithExpiry,
}
