import { trackEvent, getStateFromSource } from 'helpers/analytics'
import isEmpty from 'helpers/is-empty'

export default function RevenueTracker(
  eventName: string,
  data?: any,
  additionalProperties?: {},
) {
  const { pathname, query, user } = data
  const state_name = getStateFromSource(pathname) || ''
  const source = isEmpty(query.source)
    ? 'unknown'
    : getStateFromSource(String(query.source))
  const source_action = isEmpty(query.source_action)
    ? 'unknown'
    : String(query.source_action)

  const baseProperties: any = {
    state_name: state_name,
    user_type: user?.role?.toLowerCase(),
  }
  const sourceProperties: any = {
    source,
    source_action,
  }

  const eventProperties: any = {
    click_view_revenue_history: {
      ...sourceProperties,
      ...baseProperties,
      ...additionalProperties,
    },
    visit_revenue_history: {
      ...sourceProperties,
      ...baseProperties,
      ...additionalProperties,
    },
    click_sales: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_reset_date: {
      ...baseProperties,
      ...additionalProperties,
    },
    apply_filter_option: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_sort_by: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_export_file: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_search_box: {
      ...baseProperties,
      ...additionalProperties,
    },
    click_information_icon: {
      ...baseProperties,
    },
    click_date: {
      ...baseProperties,
      ...additionalProperties,
    },
  }

  return trackEvent(eventName, eventProperties[eventName])
}
