import { path } from './helpers'

const ROOTS_USER = '/user'
const ROOTS_AUTH = '/authentication'

const ROOTS_PROMOTER = '/promoter/hub/v1'

export const API_USER = {
  auth: {
    tokenClaim: path(ROOTS_AUTH, '/guest/v1/token/claim'),
    oneTimeToken: path(ROOTS_AUTH, '/v1/token'),
    signInByPhone: path(ROOTS_AUTH, '/guest/v1/phone/sign-in'),
    signInByEmail: path(ROOTS_AUTH, '/guest/v1/sign-in'),
    signInBySSO: path(ROOTS_AUTH, '/guest/v1/login-sso-hub'),
    getSSOToken: path(ROOTS_AUTH, '/guest/v1/get-sso'),
    requestOtpByPhone: path(ROOTS_AUTH, '/guest/v1/phone/otp/send'),
    verifyOtp: path(ROOTS_AUTH, '/guest/v1/phone/otp/verify'),
    requestHubOtp: path(ROOTS_USER, '/hub/v1/otp/send'),
    refreshToken: path(ROOTS_AUTH, '/guest/v1/refresh-token'),
    signOut: path(ROOTS_AUTH, '/v1/logout'),
  },
  profile: {
    root: path(ROOTS_USER, '/v3/profile'),
  },
  role: {
    root: path(ROOTS_USER, '/hub/v1/role'),
  },
  promoter: {
    performance_list: path(
      ROOTS_PROMOTER,
      '/conversion/performance/list',
    ),
  },
}
