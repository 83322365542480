import axios from 'axios'
import router from 'next/router'
import _get from 'lodash/get'
import sendToLog from 'helpers/send-log'
import { getBrowser } from 'helpers/user-agent'
import { getFingerprint } from 'helpers/user-device'
import {
  getLocalStorage,
  setLocalStorage,
} from 'helpers/local-storage'
import { setSession } from 'utils/jwt'

import appConfig from 'configs/app'
import authConfig from 'configs/auth'
import { API_USER } from 'routes/apis'
import isEmpty from 'helpers/is-empty'
import redirect from 'helpers/redirect'
import {
  forceDeleteCookies,
  getCookie,
  setCookie,
} from 'helpers/cookie'

const API_TIMEOUT = 20000

const DEFAULT_ERROR_MESSAGE = {
  name: 'client_side_error',
  message:
    'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi.',
}

const forceLogout = async () => {
  setSession(null)
  forceDeleteCookies()
  router.reload()
}

const apiClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL || '',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Language: 'id',
    Channel: 'WEB',
    'Country-Code': 'ID',
    'Channel-Device': getBrowser(false),
    'Channel-Fingerprint': getFingerprint(),
    'Channel-App-Version': appConfig.publicVersion,
  },
  timeout: API_TIMEOUT,
})

apiClient.interceptors.response.use(
  (response) => response,
  async function (error) {
    sendToLog(error)
    const originalRequest = error.config
    if (error?.response) {
      if (
        error.response.status === 401 &&
        error.response.data.code !== 'REFRESH_TOKEN_EXPIRED' &&
        !isEmpty(getLocalStorage(authConfig.refreshTokenName))
      ) {
        if (!originalRequest._retry) {
          try {
            originalRequest._retry = true
            const currentRefreshToken = await apiClient.post(
              API_USER.auth.refreshToken,
              {
                refresh_token: JSON.parse(
                  getLocalStorage(authConfig.refreshTokenName),
                ),
              },
            )
            if (currentRefreshToken?.data?.code === 'SUCCESS') {
              const { id_token: token } =
                currentRefreshToken.data.data
              originalRequest.headers.Authorization = `${token}`
              setSession(token)
            }
            return apiClient(originalRequest)
          } catch (error) {
            forceLogout()
          }
        } else {
          forceLogout()
        }
      } else if (error.response.status === 503) {
        redirect('/maintenance')
      }
      return Promise.reject(
        _get(error.response, 'data', DEFAULT_ERROR_MESSAGE),
      )
    }
  },
)

export default apiClient
