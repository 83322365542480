import { path } from './helpers'
import { ORGANIZER_APP_URL } from 'constants/index'

const ROOTS_AUTH = '/auth'
const ROOTS_DASHBOARD = '/dashboard'

type ProtectedPath = {
  access: string
  path: string
}

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: String(process.env.NEXT_PUBLIC_WEB_APP_REGISTER_URL),
  verify: path(ROOTS_AUTH, '/verify'),
}

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  creator_center: {
    dashboard: path(ROOTS_DASHBOARD, '/app'),
    create_content: path(ROOTS_DASHBOARD, '/create-content'),
    my_content: path(ROOTS_DASHBOARD, '/my-content'),
    subscription: path(ROOTS_DASHBOARD, '/subscription'),
    create_subscription: path(
      ROOTS_DASHBOARD,
      '/subscription/create',
    ),
    edit_subscription: path(ROOTS_DASHBOARD, `/subscription/edit`),
    revenue: path(ROOTS_DASHBOARD, '/revenue-dashboard'),
    my_promoter: path(ROOTS_DASHBOARD, '/my-promoter'),
    creator_profile: path(ROOTS_DASHBOARD, '/creator-profile'),
    event_detail: path(ROOTS_DASHBOARD, '/event'),
    event: {
      root: path('', '/event'),
      check_in: path('', '/event/check-in'),
      create_check_in: path('', '/event/check-in/create'),
      edit_check_in: path('', '/event/check-in/edit'),
      ticketing_team: path('', '/event/check-in/team'),
    },
    transfer_coin: {
      root: path('', '/transfer-coin'),
    },
  },
  general: {
    promoter_program: {
      root: path(ROOTS_DASHBOARD, '/promoter-program'),
      about: path(ROOTS_DASHBOARD, '/promoter-program/about'),
      promote: path(
        ROOTS_DASHBOARD,
        '/promoter-program/promote-content',
      ),
      sales_performance: path(
        ROOTS_DASHBOARD,
        '/promoter-program/sales-performance',
      ),
    },
    balance: {
      root: path(ROOTS_DASHBOARD, '/balance'),
      withdraw: path(ROOTS_DASHBOARD, '/balance/withdraw'),
      verification: path(ROOTS_DASHBOARD, '/balance/verification'),
    },
    verification: {
      root: path(ROOTS_DASHBOARD, '/verification'),
      coin: path(ROOTS_DASHBOARD, '/verification/coin'),
      edit_bank_account: path(
        ROOTS_DASHBOARD,
        '/verification/edit-bank-account',
      ),
    },
    my_account: path(ROOTS_DASHBOARD, '/my-account'),
    my_community: {
      root: path(ROOTS_DASHBOARD, '/my-community'),
      setting: path(
        ROOTS_DASHBOARD,
        '/my-community/community-setting',
      ),
      member: path(
        ROOTS_DASHBOARD,
        '/my-community/community-setting/pending-members',
      ),
      screening_question: path(
        ROOTS_DASHBOARD,
        '/my-community/community-setting/screening-question',
      ),
      manage_question: path(
        ROOTS_DASHBOARD,
        '/my-community/community-setting/screening-question/manage-question',
      ),
    },
    product: {
      detail: path(ROOTS_DASHBOARD, '/product/[product_uid]'),
    },
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string) =>
      path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name: string) =>
      path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name: string) =>
      path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(
      ROOTS_DASHBOARD,
      '/e-commerce/product/nike-blazer-low-77-vintage/edit',
    ),
    demoView: path(
      ROOTS_DASHBOARD,
      '/e-commerce/product/nike-air-force-1-ndestrukt',
    ),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id: string) =>
      path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(
      ROOTS_DASHBOARD,
      '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit',
    ),
    demoView: path(
      ROOTS_DASHBOARD,
      '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
    ),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title: string) =>
      path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(
      ROOTS_DASHBOARD,
      '/blog/post/apply-these-7-secret-techniques-to-improve-event',
    ),
  },
}

export const PROTECTED_PATH: ProtectedPath[] = [
  {
    access: 'promoter-program',
    path: PATH_DASHBOARD.general.promoter_program.root,
  },
  {
    access: 'about-promoter',
    path: PATH_DASHBOARD.general.promoter_program.about,
  },
  {
    access: 'sales-performance',
    path: PATH_DASHBOARD.general.promoter_program.sales_performance,
  },
  {
    access: 'promote-content',
    path: PATH_DASHBOARD.general.promoter_program.promote,
  },
  { access: 'balance', path: PATH_DASHBOARD.general.balance.root },
  { access: 'my-account', path: PATH_DASHBOARD.general.my_account },
  {
    access: 'dashboard',
    path: PATH_DASHBOARD.creator_center.dashboard,
  },
  {
    access: 'create-content',
    path: PATH_DASHBOARD.creator_center.create_content,
  },
  {
    access: 'digital-content',
    path: PATH_DASHBOARD.creator_center.my_content,
  },
  {
    access: 'live-session',
    path: PATH_DASHBOARD.creator_center.my_content,
  },
  {
    access: 'course',
    path: PATH_DASHBOARD.creator_center.my_content,
  },
  { access: 'revenue', path: PATH_DASHBOARD.creator_center.revenue },
  {
    access: 'my-affiliate',
    path: PATH_DASHBOARD.creator_center.my_promoter,
  },
  {
    access: 'creator-profile',
    path: PATH_DASHBOARD.creator_center.creator_profile,
  },
  {
    access: 'my-promoter',
    path: PATH_DASHBOARD.creator_center.my_promoter,
  },
  {
    access: 'product-detail',
    path: PATH_DASHBOARD.creator_center.event_detail,
  },
  {
    access: 'my-subscriptions',
    path: PATH_DASHBOARD.creator_center.subscription,
  },
  {
    access: 'create-subscription',
    path: PATH_DASHBOARD.creator_center.create_subscription,
  },
  {
    access: 'create-subscription',
    path: PATH_DASHBOARD.creator_center.edit_subscription,
  },
  {
    access: 'create-subscription',
    path: `${PATH_DASHBOARD.creator_center.edit_subscription}/[subscription_uid]`,
  },
  {
    access: 'event-check-in-list',
    path: `${ORGANIZER_APP_URL}/organization`,
  },
  {
    access: 'create-event-check-in',
    path: PATH_DASHBOARD.creator_center.event.create_check_in,
  },
  {
    access: 'edit-event-check-in',
    path: PATH_DASHBOARD.creator_center.event.edit_check_in,
  },
  {
    access: 'edit-event-check-in',
    path: `${PATH_DASHBOARD.creator_center.event.edit_check_in}/[checkin_uid]`,
  },
  {
    access: 'event-check-in-detail',
    path: `${PATH_DASHBOARD.creator_center.event.check_in}/[checkin_uid]`,
  },
  {
    access: 'ticketing-team',
    path: `${ORGANIZER_APP_URL}/event/check-in/team`,
  },
  {
    access: 'my-community',
    path: PATH_DASHBOARD.general.my_community.root,
  },
  {
    access: 'community-setting',
    path: PATH_DASHBOARD.general.my_community.setting,
  },
  {
    access: 'data-verification',
    path: PATH_DASHBOARD.general.verification.root,
  },
  {
    access: 'transfer-coin',
    path: PATH_DASHBOARD.creator_center.transfer_coin.root,
  },
]

export const PATH_DOCS =
  'https://docs-minimals.vercel.app/introduction'
