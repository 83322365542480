import PromoterTracker from 'domains/PromoterProgram/trackers'
import MyPromoterTracker from 'domains/Promoter/trackers'
import MyBalanceTracker from 'domains/Balance/trackers/Balance'
import WithdrawalTracker from 'domains/Balance/trackers/Withdrawal'
import VerificationTracker from 'domains/Balance/trackers/Verification'
import UserTracker from 'domains/Dashboard/trackers'
import AuthTracker from 'domains/Users/Auth/trackers'
import RevenueTracker from 'domains/Revenue/trackers'
import CommunityTracker from 'domains/Community/trackers'
import EventTracker from 'domains/Product/trackers'
import ContentTracker from 'domains/Content/trackers'

const trackEvent = {
  promoter: PromoterTracker,
  myPromoter: MyPromoterTracker,
  myBalance: MyBalanceTracker,
  withdrawal: WithdrawalTracker,
  verification: VerificationTracker,
  user: UserTracker,
  auth: AuthTracker,
  revenue: RevenueTracker,
  community: CommunityTracker,
  event: EventTracker,
  content: ContentTracker,
}

export default trackEvent
